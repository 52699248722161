<template>
  <div style="background: white">
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_operationStatusDetail" />
      <v-form ref="form" lazy-validation onsubmit="return false;">
        <v-container fluid>
          <div class="holyGrail" style="padding: 5px">
            <nav class="side-nav">
              <v-row class="search-row">
                <!-- カレンダー -->
                <!-- 運行日-->
                <div class="date-style">
                  <v-text-field
                    outlined
                    dense
                    v-model="operationDate"
                    :label="$t('label.lbl_operationDate')"
                    @change="changeDate"
                    :rules="[rules.yyyymmdd]"
                    class="txt-single date-style"
                    clear-icon="mdi-close-circle"
                    clearable
                  ></v-text-field>
                </div>
                <span class="require asterisk-spacer">*</span>
                <div class="calender-btn-area">
                  <v-menu
                    v-model="dateCalender"
                    :close-on-content-click="false"
                    :nudge-right="-100"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    class="date-calendar"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" text x-small>
                        <v-icon>mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="operationDateCal"
                      @input="dateCalender = false"
                      @change="handleChange"
                    ></v-date-picker>
                  </v-menu>
                  <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
                <!-- カレンダー終わり -->
                <!-- 社号一覧 -->
                <v-container fluid class="main-area">
                  <v-data-table
                    id="listData"
                    fixed-header
                    :headers="headerDriveName"
                    :items="driveNameList"
                    :ripple="false"
                    disable-filtering
                    :hide-default-footer="true"
                    height="440px"
                    @click:row="clickRow"
                  >
                  </v-data-table>
                </v-container>
              </v-row>
              <!-- 戻るボタン-->
              <div align="center">
                <v-btn
                  color="primary"
                  type="button"
                  class="other-btn"
                  v-on:click="backbtn()"
                  style="margin: 25px"
                >
                  {{ $t("btn.btn_back") }}
                </v-btn>
              </div>
            </nav>
            <main class="content">
              <!-- GoogleMap -->
              <GoogleMap
                :carIconInfoList="currentLocationList"
                :operationRouteLine="operationRoute"
                @clickCarIcon="clickCarIcon"
                :value="paramRoutePath"
                @childRoutepath="paramRoutePath = $event"
                ref="gmap"
              />
            </main>
          </div>
        </v-container>
      </v-form>
      <!-- 詳細一覧 -->
      <!-- 運行状況詳細表示 -->
      <v-simple-table fixed-header class="operationDetailList" id="listData" height="220px">
        <template v-slot:default>
          <thead>
            <tr>
              <!-- No -->
              <th class="text-center">{{ $t("label.lbl_No") }}</th>
              <!-- 便 -->
              <th class="text-center">{{ $t("label.lbl_transportNo") }}</th>
              <!-- 乗務員 -->
              <th>{{ $t("label.lbl_driver") }}</th>
              <!-- 拠点名 -->
              <th>{{ $t("label.lbl_baseName") }}</th>
              <!-- 出発日時(計画) -->
              <th class="text-center">{{ $t("label.lbl_departureScheduleDate") }}</th>
              <!-- 出発日時(実績) -->
              <th class="text-center">{{ $t("label.lbl_departureActualDate") }}</th>
              <!-- 到着日時(計画) -->
              <th class="text-center">{{ $t("label.lbl_reachScheduleDate") }}</th>
              <!-- 到着日時(実績) -->
              <th class="text-center">{{ $t("label.lbl_reachActualDate") }}</th>
              <!-- ステータス -->
              <th>{{ $t("label.lbl_status") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="od in operationStatusList" :key="od.no" :class="isDelayedDetailRow(od)">
              <!-- No -->
              <td class="text-center">{{ od.no }}</td>
              <!-- 便 -->
              <td class="text-center">{{ od.transport }}</td>
              <!-- 乗務員 -->
              <td>{{ od.driver }}</td>
              <!-- 拠点名 -->
              <td>{{ od.baseName }}</td>
              <!-- 出発日時(計画) -->
              <td class="text-center">{{ od.departureScheduleDate }}</td>
              <!-- 出発日時(実績) -->
              <td class="text-center">{{ od.departureActualDate }}</td>
              <!-- 到着日時(計画) -->
              <td class="text-center">{{ od.arrivalScheduleDate }}</td>
              <!-- 到着日時(実績) -->
              <td class="text-center">{{ od.arrivalActualDate }}</td>
              <!-- ステータス -->
              <td>{{ od.status }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :firstPageFlag="infoDialog.firstPageFlag"
      :thirdPageFlag="infoDialog.thirdPageFlag"
    />
  </div>
</template>

<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import NavBar from "../../components/NavBar.vue";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import GoogleMap from "./GoogleMap.vue";
import SimpleDialog from "@/components/SimpleDialog";

export default {
  name: appConfig.MENU_ID.P_OPM_002,
  components: {
    NavBar,
    GoogleMap,
    SimpleDialog,
  },
  watch: {
    operationDateCal: {
      handler(val) {
        this.operationDate = this.formatDate(val);
      },
      deep: true,
    },
  },
  data: () => ({
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },

    // ローディング用
    loadingCounter: 0,
    // 車両SID
    carSidList: [],
    // 社号一覧
    driveNameList: [],
    // 社号別現在地取得APIから取得した情報
    currentLocationList: [],
    // 社号運行ルート取得APIから取得したデータ
    operationRoute: {
      carSid: "",
      route: [],
      status: "",
    },
    // 社号運行状況取得APIから取得したデータ
    operationStatusList: [],
    // 運行日
    operationDate: "",
    // 運行日カレンダーオブジェクト
    operationDateCal: "",
    // 子コンポーネントから渡される運行ルート
    paramRoutePath: "",
    // 運行日カレンダー
    dateCalender: false,
    // 入力チェック
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
    },
    // 社号一覧ヘッダ
    headerDriveName: [
      {
        text: i18n.tc("label.lbl_driverName"),
        value: "driveName",
        width: "100%",
        align: "center",
        sortable: false,
      },
    ],
  }),

  created() {
    // 前画面から受け取った値を保持する。
    if (this.$route.params.searchParam) {
      this.searchParam = this.$route.params.searchParam;
    }

    this.operationDate = this.$route.params.operationDate;
    var carSidList = this.$route.params.carSidList;
    var carShagoList = this.$route.params.carShagoList;
    // TODO テスト用にデータセット
    // this.operationDate = "2024/11/11";
    // carSidList = ["a0001", "b0002", "c0003", "d0004", "e0005", "f0006"];
    // carShagoList = ["AAS015", "BDS015", "CCS915", "DDV515", "EEB110", "FFS915"];
    // 前画面から受け取る運行日はyyyy/MM/ddなので、カレンダコンポーネントで使えるようyyyy-MM-ddに変換する。
    const [year, month, day] = this.operationDate.split("/");
    this.operationDateCal = `${year}-${month}-${day}`;
    // 前画面から受け取った社号リストをテーブルにセットできるよう編集
    for (let driveName of carShagoList) {
      this.driveNameList.push({ driveName: driveName });
    }
    this.carSidList = carSidList;
  },
  mounted() {
    this.$refs.gmap.init();
    this.init();
  },

  methods: {
    /**
     * 初期表示
     */
    init() {
      this.operationStatusList = null;
      this.getBusinessVehicleLocation(this.operationDateCal, this.carSidList).then(() => {
        this.$refs.gmap.loaded.then(() => {
          this.$refs.gmap.removeLine(this.paramRoutePath);
          this.$refs.gmap.redisplayCarIcons(this.currentLocationList);
        });
      });
    },
    /**
     * カレンダーから直接クリックした場合、その運行日の車両状況をマップ上に表示する。
     */
    handleChange() {
      this.operationStatusList = null;
      this.getBusinessVehicleLocation(this.operationDateCal, this.carSidList).then(() => {
        this.$refs.gmap.removeLine(this.paramRoutePath);
        this.$refs.gmap.redisplayCarIcons(this.currentLocationList);
      });
    },
    /**
     * 戻るボタン処理
     */
    backbtn() {
      this.checkFlg = true;

      this.$router.push({
        name: appConfig.SCREEN_ID.P_OPM_001,
        // 検索条件
        params: {
          searchParam: this.searchParam,
        },
      });
    },

    /**
     * 車両アイコンを押下した場合、その車両の運行ルートと詳細一覧を表示する。
     */
    clickCarIcon(carSid) {
      var row = {
        carSid: carSid,
      };
      this.clickRow(row);
    },

    /**
     * 社号一覧の社号を押下した場合、その車両の運行ルートと詳細一覧を表示する。
     *
     */
    clickRow(row) {
      // 運行日が入力されていない場合、処理を終了する。
      if (!this.operationDateCal) {
        return;
      }

      let carSid = "";
      if (row.driveName) {
        // 社号をクリックした場合、社号リストと車両SIDは同じ順番で前画面から渡されているため、
        // 行番号をもとに車両SIDリストから取得する。
        carSid = this.carSidList[this.driveNameList.indexOf(row)];
      } else {
        // 車両アイコンを押下した場合、GoogleMap.vue側でAPIの実行結果を元に設定しているので、
        // そのまま車両SIDを設定。
        carSid = row.carSid;
      }

      Promise.all([
        this.getOperationRouteList(carSid, this.operationDateCal),
        this.getOperationStatusList(carSid, this.operationDateCal),
      ])
        .then((result) => {
          const jsonData1 = JSON.parse(JSON.stringify(result[0].data));
          // 正常時
          if (jsonData1.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            this.operationRoute.carSid = jsonData1.resIdv.route.carSid;
            this.operationRoute.status = jsonData1.resIdv.route.status;
            let routeList = [];
            for (const route of jsonData1.resIdv.route.route) {
              routeList.push({
                latitude: route.latitude ?? "",
                longitude: route.longitude ?? "",
                address: route.address ?? "",
                prevLatitude: route.prevLatitude ?? "",
                prevLongitude: route.prevLongitude ?? "",
              });
            }
            this.operationRoute.route = routeList;
          } else {
            throw jsonData1.resCom.resComMessage;
          }

          const jsonData2 = JSON.parse(JSON.stringify(result[1].data));
          if (jsonData2.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
            let operationStatusList = [];
            var i = 0;
            // 便ごとに分けて１から採番する。
            for (const os of jsonData2.resIdv.operationStatusList) {
              operationStatusList.push({
                no: ++i,
                transport: os.transport ?? "",
                driver: os.driver ?? "",
                baseName: os.baseName ?? "",
                departureScheduleDate: os.departureScheduleDate ?? "",
                departureActualDate: os.departureActualDate ?? "",
                arrivalScheduleDate: os.arrivalScheduleDate ?? "",
                arrivalActualDate: os.arrivalActualDate ?? "",
                status: os.status ?? "",
                delayStatus: os.delayStatus ?? "",
              });
            }
            this.operationStatusList = operationStatusList;
          } else {
            throw jsonData2.resCom.resComMessage;
          }
          if (
            jsonData1.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS &&
            jsonData2.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS
          ) {
            if (jsonData1.resCom.resComCount === 0 || jsonData2.resCom.resComCount === 0) {
              this.infoDialog.message = i18n.tc("check.chk_dataNotFound");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
          }
          // すでに描画されている運行ルート線を消去する。
          this.$refs.gmap.removeLine(this.paramRoutePath);
          this.$refs.gmap.removeTruckMarker();
          if (jsonData1.resCom.resComCount != 0 && jsonData2.resCom.resComCount != 0) {
            // 運行ルート線を描画する。
            this.$refs.gmap.drawLine(this.operationRoute);
            // 車両アイコンを表示する。
            this.$refs.gmap.putCarIcon(this.operationRoute);
          }
        })
        .catch((err) => {
          this.infoDialog.message = err;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        });
    },

    /**
     * 社号別現在地取得API実施
     */
    getBusinessVehicleLocation(operationDate, carSidList) {
      // テスト用ダミー処理
      // this.$httpClient.secureGet = function () {
      //   return new Promise((resolve) => {
      //     resolve({
      //       data: {
      //         resCom: {
      //           resComStatus: "Success",
      //           resComCode: "000",
      //           resComCount: 2,
      //         },
      //         resIdv: {
      //           locations: [
      //             {
      //               carSid: "a0001",
      //               latitude: null,
      //               longitude: null,
      //               address: "群馬県沼田市下之町８８８", // ジオコーディングエラー用データ"県koemこれｍさづる１２１",
      //               prevLatitude: null,
      //               prevLongitude: null,
      //               status: 0,
      //             },

      //             {
      //               carSid: "b0002",
      //               latitude: 35.768079,
      //               longitude: 139.779233,
      //               address: "", // 東京
      //               prevLatitude: 30.708079,
      //               prevLongitude: 144.709233,
      //               status: 1,
      //             },
      //             {
      //               carSid: "c0003",
      //               latitude: 35.368079,
      //               longitude: 139.779233,
      //               address: "", // 横浜
      //               prevLatitude: 35.068079,
      //               prevLongitude: 139.079233,
      //               status: 1,
      //             },
      //             {
      //               carSid: "d0004",
      //               latitude: null,
      //               longitude: null,
      //               address: "宮城県仙台市青葉区国分町３丁目７−１",
      //               prevLatitude: null,
      //               prevLongitude: null,
      //               status: 1,
      //             },

      //             {
      //               carSid: "e0005",
      //               latitude: 30.368079,
      //               longitude: 145.779233,
      //               address: "", // 横浜
      //               prevLatitude: 35.068079,
      //               prevLongitude: 150.079233,
      //               status: 1,
      //             },
      //             {
      //               carSid: "f0006",
      //               latitude: 30.3,
      //               longitude: 143.7,
      //               address: "",
      //               prevLatitude: 35.3555,
      //               prevLongitude: 143.65555,
      //               status: 5,
      //             },
      //           ],
      //         },
      //       },
      //     });
      //   });
      // };
      // リクエストの設定
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.instDate = this.convertOperationDateCal(operationDate);
      config.params.carSids = carSidList;
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.BIZ_GET_VEHICLES_LOCATION, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              let list = [];
              if (jsonData.resCom.resComCount === 0) {
                this.infoDialog.message = i18n.tc("check.chk_dataNotFound");
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;
              }
              for (const row of jsonData.resIdv.locations) {
                list.push({
                  carSid: row.carSid ?? "",
                  latitude: row.latitude ?? "",
                  longitude: row.longitude ?? "",
                  address: row.address ?? "",
                  prevLatitude: row.prevLatitude ?? "",
                  prevLongitude: row.prevLongitude ?? "",
                  status: row.status,
                });
              }
              this.currentLocationList = list;
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
    /**
     * 社号運行ルート取得API実施
     */
    getOperationRouteList(carSid, operationDate) {
      // テスト用ダミー処理
      // this.$httpClient.secureGet = function () {
      //   return new Promise((resolve) => {
      //     if (carSid == "a0001") {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             route: {
      //               carSid: "a0001",

      //               route: [
      //                 {
      //                   latitude: null,
      //                   longitude: null,
      //                   address: "群馬県沼田市下之町８８８", // ジオコーディングエラー用データ"県koemこれｍさづる１２１",
      //                   prevLatitude: null,
      //                   prevLongitude: null,
      //                 },
      //               ],
      //               status: "0",
      //             },
      //           },
      //         },
      //       });
      //     } else if (carSid == "b0002") {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             route: {
      //               carSid: "b0002",

      //               route: [
      //                 {
      //                   latitude: 35.6809591,
      //                   longitude: 139.7673068,
      //                   address: null,
      //                   prevLatitude: 35.6809591,
      //                   prevLongitude: 139.7673068,
      //                 },
      //                 {
      //                   latitude: 34.9717847,
      //                   longitude: 138.3891858,
      //                   address: null,
      //                   prevLatitude: 35.6809591,
      //                   prevLongitude: 139.7673068,
      //                 },
      //                 {
      //                   latitude: 35.170915,
      //                   longitude: 136.881537,
      //                   address: null,
      //                   prevLatitude: 34.9717847,
      //                   prevLongitude: 138.3891858,
      //                 },
      //               ],
      //               status: "1",
      //             },
      //           },
      //         },
      //       });
      //     } else if (carSid == "c0003") {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             route: {
      //               carSid: "c0003",

      //               route: [
      //                 {
      //                   latitude: 33.541,
      //                   longitude: 130.4948,
      //                   address: null,
      //                   prevLatitude: 33.541,
      //                   prevLongitude: 130.4948,
      //                 },
      //                 {
      //                   latitude: 33.57417,
      //                   longitude: 130.57319,
      //                   address: null,
      //                   prevLatitude: 33.541,
      //                   prevLongitude: 130.4948,
      //                 },
      //                 {
      //                   latitude: 33.5,
      //                   longitude: 132.46,
      //                   address: null,
      //                   prevLatitude: 33.57417,
      //                   prevLongitude: 130.57319,
      //                 },
      //                 {
      //                   latitude: 34.2603,
      //                   longitude: 135.1358,
      //                   address: null,
      //                   prevLatitude: 33.5,
      //                   prevLongitude: 132.46,
      //                 },
      //               ],
      //               status: "5",
      //             },
      //           },
      //         },
      //       });
      //     } else if (carSid == "d0004") {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             route: {
      //               carSid: "d0004",
      //               route: [
      //                 {
      //                   latitude: null,
      //                   longitude: null,
      //                   address: "県koemこれｍさづる１２１",
      //                   prevLatitude: null,
      //                   prevLongitude: null,
      //                 },
      //               ],
      //               status: "1",
      //             },
      //           },
      //         },
      //       });
      //     } else if (carSid == "e0005") {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             route: {
      //               carSid: "e0005",

      //               route: [
      //                 {
      //                   latitude: 25.368079,
      //                   longitude: 135.779233,
      //                   address: null,
      //                   prevLatitude: 25.368079,
      //                   prevLongitude: 135.779233,
      //                 },
      //                 {
      //                   latitude: 30.368079,
      //                   longitude: 145.779233,
      //                   address: null,
      //                   prevLatitude: 35.068079,
      //                   prevLongitude: 150.079233,
      //                 },
      //               ],
      //               status: "1",
      //             },
      //           },
      //         },
      //       });
      //     } else if (carSid == "f0006") {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             route: {
      //               carSid: "f0006",

      //               route: [
      //                 {
      //                   latitude: 30.368079,
      //                   longitude: 145.779233,
      //                   address: null,
      //                   prevLatitude: 35.068079,
      //                   prevLongitude: 150.079233,
      //                 },
      //                 {
      //                   latitude: 30.3,
      //                   longitude: 143.7,
      //                   address: null,
      //                   prevLatitude: 35.3555,
      //                   prevLongitude: 135.779233,
      //                 },
      //               ],
      //               status: "5",
      //             },
      //           },
      //         },
      //       });
      //     }
      //   });
      // };

      // リクエストの設定
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.carSid = carSid;
      config.params.instDate = this.convertOperationDateCal(operationDate);

      return this.$httpClient.secureGet(appConfig.API_URL.BIZ_GET_VEHICLE_ROUTE, config);
    },
    /**
     * 社号運行状況取得API実施
     */
    getOperationStatusList(carSid, operationDate) {
      // テスト用ダミー処理
      // this.$httpClient.secureGet = function () {
      //   return new Promise((resolve) => {
      //     if (carSid == "a0001") {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             operationStatusList: [
      //               {
      //                 transport: "1",
      //                 driver: "田中太郎",
      //                 baseName: "XX営業所",
      //                 departureScheduleDate: "11/06(水) 09:00:00",
      //                 departureActualDate: "11/06(水) 09:00:00",
      //                 arrivalScheduleDate: "-",
      //                 arrivalActualDate: "-",
      //                 status: "集荷・配達済み",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 driver: "田中太郎",
      //                 transport: "1",
      //                 baseName: "YY営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "11/06(水) 10:05:00",
      //                 arrivalScheduleDate: "11/06(水) 09:00:00",
      //                 arrivalActualDate: "11/06(水) 09:00:00",
      //                 status: "集荷・配達済み",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 transport: "1",
      //                 driver: "田中太郎",
      //                 baseName: "ZZ営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "11/06(水) 11:35:00",
      //                 arrivalScheduleDate: "11/06(水) 11:00:00",
      //                 arrivalActualDate: "11/06(水) 11:05:00",
      //                 status: "集荷・配達済み",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 transport: "1",
      //                 driver: "田中太郎",
      //                 baseName: "XX営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "-",
      //                 arrivalScheduleDate: "11/06(水) 12:00:00",
      //                 arrivalActualDate: "11/06(水) 12:05:00",
      //                 status: "集荷・配達済み",
      //                 delayStatus: 1,
      //               },
      //               {
      //                 transport: "2",
      //                 driver: "田中太郎",
      //                 baseName: "XX営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "11/06(水) 15:00:00",
      //                 arrivalScheduleDate: "-",
      //                 arrivalActualDate: "-",
      //                 status: "未集荷・未配達",
      //                 delayStatus: 1,
      //               },
      //               {
      //                 driver: "田中太郎",
      //                 transport: "2",
      //                 baseName: "YY営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "-",
      //                 arrivalScheduleDate: "11/06(水) 15:00:00",
      //                 arrivalActualDate: "-",
      //                 status: "未集荷・未配達",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 transport: "2",
      //                 driver: "田中太郎",
      //                 baseName: "ZZ営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "-",
      //                 arrivalScheduleDate: "11/06(水) 17:00:00",
      //                 arrivalActualDate: "-",
      //                 status: "未集荷・未配達",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 transport: "2",
      //                 driver: "田中太郎",
      //                 baseName: "AA営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "-",
      //                 arrivalScheduleDate: "11/06(水) 17:45:00",
      //                 arrivalActualDate: "-",
      //                 status: "未集荷・未配達",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 transport: "2",
      //                 driver: "田中太郎",
      //                 baseName: "BB営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "-",
      //                 arrivalScheduleDate: "11/06(水) 18:00:00",
      //                 arrivalActualDate: "-",
      //                 status: "未集荷・未配達",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 transport: "2",
      //                 driver: "田中太郎",
      //                 baseName: "XX営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "-",
      //                 arrivalScheduleDate: "11/06(水) 18:45:00",
      //                 arrivalActualDate: "-",
      //                 status: "未集荷・未配達",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 transport: "3",
      //                 driver: "田中太郎",
      //                 baseName: "XX営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "-",
      //                 arrivalScheduleDate: "-",
      //                 arrivalActualDate: "-",
      //                 status: "未集荷・未配達",
      //                 delayStatus: 0,
      //               },
      //             ],
      //           },
      //         },
      //       });
      //     } else if (carSid == "b0002") {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             operationStatusList: [
      //               {
      //                 transport: "2",
      //                 driver: "田中太郎",
      //                 baseName: "BB営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "-",
      //                 arrivalScheduleDate: "11/06(水) 18:00:00",
      //                 arrivalActualDate: "-",
      //                 status: "未集荷・未配達",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 transport: "2",
      //                 driver: "田中太郎",
      //                 baseName: "XX営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "-",
      //                 arrivalScheduleDate: "11/06(水) 18:45:00",
      //                 arrivalActualDate: "-",
      //                 status: "未集荷・未配達",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 transport: "3",
      //                 driver: "田中太郎",
      //                 baseName: "XX営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "-",
      //                 arrivalScheduleDate: "-",
      //                 arrivalActualDate: "-",
      //                 status: "未集荷・未配達",
      //                 delayStatus: 0,
      //               },
      //             ],
      //           },
      //         },
      //       });
      //     } else if (carSid == "c0003") {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             operationStatusList: [
      //               {
      //                 transport: "1",
      //                 driver: "興家彰吾",
      //                 baseName: "AA営業所",
      //                 departureScheduleDate: "11/06(水) 09:00:00",
      //                 departureActualDate: "11/06(水) 09:00:00",
      //                 arrivalScheduleDate: "-",
      //                 arrivalActualDate: "-",
      //                 status: "集荷・配達済み",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 transport: "1",
      //                 driver: "興家彰吾",
      //                 baseName: "BB営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "11/06(水) 10:05:00",
      //                 arrivalScheduleDate: "11/06(水) 09:00:00",
      //                 arrivalActualDate: "11/06(水) 09:00:00",
      //                 status: "集荷・配達済み",
      //                 delayStatus: 0,
      //               },
      //             ],
      //           },
      //         },
      //       });
      //     } else if (carSid == "d0004") {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             operationStatusList: [
      //               {
      //                 transport: "1",
      //                 driver: "榊原健斗",
      //                 baseName: "AA営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "-",
      //                 arrivalScheduleDate: "-",
      //                 arrivalActualDate: "-",
      //                 status: "未集荷・未配達",
      //                 delayStatus: 0,
      //               },
      //             ],
      //           },
      //         },
      //       });
      //     } else if (carSid == "e0005") {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             operationStatusList: [
      //               {
      //                 transport: "1",
      //                 driver: "ダニエル・ダン",
      //                 baseName: "XX営業所",
      //                 departureScheduleDate: "11/06(水) 09:00:00",
      //                 departureActualDate: "11/06(水) 09:00:00",
      //                 arrivalScheduleDate: "-",
      //                 arrivalActualDate: "-",
      //                 status: "集荷・配達済み",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 driver: "ダニエル・ダン",
      //                 transport: "1",
      //                 baseName: "YY営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "11/06(水) 10:05:00",
      //                 arrivalScheduleDate: "11/06(水) 09:00:00",
      //                 arrivalActualDate: "11/06(水) 09:00:00",
      //                 status: "集荷・配達済み",
      //                 delayStatus: 1,
      //               },
      //               {
      //                 transport: "1",
      //                 driver: "田中太郎",
      //                 baseName: "ZZ営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "11/06(水) 11:35:00",
      //                 arrivalScheduleDate: "11/06(水) 11:00:00",
      //                 arrivalActualDate: "11/06(水) 11:05:00",
      //                 status: "集荷・配達済み",
      //                 delayStatus: 1,
      //               },
      //             ],
      //           },
      //         },
      //       });
      //     } else if (carSid == "f0006") {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             operationStatusList: [
      //               {
      //                 transport: "1",
      //                 driver: "鈴木市子",
      //                 baseName: "XX営業所",
      //                 departureScheduleDate: "11/06(水) 09:00:00",
      //                 departureActualDate: "11/06(水) 09:00:00",
      //                 arrivalScheduleDate: "-",
      //                 arrivalActualDate: "-",
      //                 status: "集荷・配達済み",
      //                 delayStatus: 0,
      //               },
      //               {
      //                 driver: "鈴木市子",
      //                 transport: "2",
      //                 baseName: "YY営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "11/06(水) 10:05:00",
      //                 arrivalScheduleDate: "11/06(水) 09:00:00",
      //                 arrivalActualDate: "11/06(水) 09:00:00",
      //                 status: "集荷・配達済み",
      //                 delayStatus: 1,
      //               },
      //               {
      //                 transport: "2",
      //                 driver: "鈴木市子",
      //                 baseName: "ZZ営業所",
      //                 departureScheduleDate: "-",
      //                 departureActualDate: "11/06(水) 11:35:00",
      //                 arrivalScheduleDate: "11/06(水) 11:00:00",
      //                 arrivalActualDate: "11/06(水) 11:05:00",
      //                 status: "集荷・配達済み",
      //                 delayStatus: 0,
      //               },
      //             ],
      //           },
      //         },
      //       });
      //     }
      //   });
      // };
      // リクエストの設定
      const config = this.$httpClient.createGetApiRequestConfig();
      config.params.operationDate = this.convertOperationDateCal(operationDate);
      config.params.carSid = carSid;
      return this.$httpClient.secureGet(
        appConfig.API_URL.BIZ_GET_OPERATION_STATUS_VEHICLE_CODE,
        config
      );
    },

    // 運行詳細一覧の遅延判定(行)
    isDelayedDetailRow(operationDetail) {
      if (operationDetail.delayStatus == "1") {
        return "delayed";
      }
      return "";
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 運行日付直接入力
     */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.operationDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.operationDate = null;
        this.operationDateCal = null;
        return;
      }
      this.operationStatusList = null;

      this.getBusinessVehicleLocation(this.operationDateCal, this.carSidList).then(() => {
        this.$refs.gmap.removeLine(this.paramRoutePath);
        this.$refs.gmap.redisplayCarIcons(this.currentLocationList);
      });
    },
    /**
     * 運行日付を1日遡る
     */
    prevDate() {
      if (this.operationDateCal == null) {
        return;
      }
      let date = new Date(this.operationDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.operationDateCal != toDate) {
        this.operationDateCal = toDate;
      } else {
        this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
      this.operationStatusList = null;

      this.getBusinessVehicleLocation(this.operationDateCal, this.carSidList).then(() => {
        this.$refs.gmap.removeLine(this.paramRoutePath);
        this.$refs.gmap.redisplayCarIcons(this.currentLocationList);
      });
    },

    /**
     * 運行日付を1日進める
     */
    nextDate() {
      if (this.operationDateCal == null) {
        return;
      }
      let date = new Date(this.operationDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.operationDateCal != toDate) {
        this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
      this.operationStatusList = null;

      this.getBusinessVehicleLocation(this.operationDateCal, this.carSidList).then(() => {
        this.$refs.gmap.removeLine(this.paramRoutePath);
        this.$refs.gmap.redisplayCarIcons(this.currentLocationList);
      });
    },

    /**
     * APIに渡すため、operationDateCalを「YYYY-MM-DD」から「YYYY/MM/DD」に変換し、UTC形式にする。
     *
     */
    convertOperationDateCal(operationDateCal) {
      const [year, month, day] = operationDateCal.split("-");
      return dateTimeHelper.convertUTC(`${year}/${month}/${day}`);
    },
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.holyGrail {
  display: flex;
  flex: 1;
}

.side-nav {
  width: 315px;
}
.content {
  flex: 1;
}
/* タイトル行の背景色 */
thead th {
  background-color: #effad9;
}
/* 遅延行の背景色 */
tr.delayed td {
  background-color: #ff6262;
}
</style>
