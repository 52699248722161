<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_vehicleOperationList" />
      <v-form ref="form" lazy-validation>
        <v-container fluid>
          <v-row class="search-row">
            <!-- カレンダー -->
            <!-- 運行日-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="operationDate"
                :label="$t('label.lbl_operationDate')"
                @change="changeDate"
                :rules="[rules.yyyymmdd]"
                class="txt-single date-style"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <div class="calender-btn-area">
              <v-menu
                v-model="dateCalender"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="operationDateCal"
                  @input="dateCalender = false"
                ></v-date-picker>
              </v-menu>
              <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text x-small>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text x-small>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- カレンダー終わり -->

            <!-- 営業所 -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="officeSidSelected"
                :items="officeList"
                :label="$t('label.lbl_salesOffice')"
                :hint="setOffice()"
                :rules="[rules.inputRequired]"
                :search-input.sync="getOfficeSelected"
                @change="changeOffice"
                class="txt-single"
                margin-bottom="0"
                persistent-hint
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="test"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require" style="float: left">*</span>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- ステータス -->
            <div class="">
              <v-select
                dense
                v-model="operationStatus"
                :items="operationStatusList"
                :label="$t('label.lbl_status')"
                class="txt-single"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-select>
            </div>
          </v-row>

          <v-row>
            <!--ボタン領域-->
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="searchButtonClick()">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div style="float: right">
                <!-- 運行状況確認ボタン -->
                <v-btn color="primary" class="api-btn" @click="showOperationStatus()"
                  >{{ $t("btn.btn_vehicleOperationStatus") }}
                </v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row" style="margin-top: 10px">
              <!--検索結果件数表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--ページング数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>
                <span class="item-spacer" style="float: left">&nbsp;</span>
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>

      <!-- 検索結果 -->
      <v-container fluid class="main-area">
        <v-data-table
          id="listData"
          class="searchResult"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="599px"
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }" class="product-fixed1">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>

          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              @click="checkList(item, index)"
              style="transform: scale(2)"
            />
          </template>

          <!-- 車両 -->
          <template v-slot:[`item.carSid`]="{ item }">
            <div>
              {{ item.carShago }}
            </div>
          </template>

          <!-- 便情報一覧 -->
          <template v-slot:[`item.operationStatusList`]="{ item }">
            <div class="transport-info-area" v-if="item.operationStatusList != null">
              <dl
                v-for="(dispatchBin, binIndex) in item.operationStatusList"
                :key="dispatchBin.transportSid + '.' + binIndex"
              >
                <v-card
                  class="transport-info-box"
                  :class="getBackgroundColor(dispatchBin.binStatusDiv)"
                  @click="showDetailDialog(operationDateUTC, item.carSid)"
                >
                  <div
                    style="
                      font-size: 14px;
                      color: white;
                      display: flex;
                      flex-flow: column;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <span class="dispatchBinDelayIcon" v-if="dispatchBin.binDelayDiv != 0">
                      ⚠️
                    </span>
                    <p class="binStatus">
                      <span>{{ binIndex + 1 }}{{ $t("label.lbl_transportNo") }}</span>
                      &ensp;
                      <span>{{ binStatusText(dispatchBin.binStatusDiv) }} </span>
                    </p>
                    <div class="operationStatus">
                      <v-progress-linear
                        :value="dispatchBin.operationProgressValue"
                        height="100%"
                        background-color="grey"
                        color="black"
                      >
                        {{ dispatchBin.operationProgressText }}
                      </v-progress-linear>
                    </div>
                  </div>
                </v-card>
              </dl>
            </div>
            <div v-else>空車です。</div>
          </template>
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>

      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :title="confirmDialog.title"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
        :cancelBtnFlg="confirmDialog.cancelBtnFlg"
        :outsideClickNotCloseFlg="confirmDialog.outsideClickNotCloseFlg"
      />

      <v-dialog v-model="showWarningMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ warningMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeWarningMessage"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 運行状況詳細ダイアログ -->
      <v-dialog v-model="operationDetailDialog" :max-width="1800">
        <v-card>
          <v-card-title primary-title>
            {{ $t("label.lbl_operationStatusDetail") }}
            <v-spacer></v-spacer>
            <v-btn icon @click="operationDetailDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <!-- 運行状況詳細表示 -->
          <v-simple-table fixed-header class="operationDetailList" id="listData">
            <template v-slot:default>
              <thead>
                <tr>
                  <!-- No -->
                  <th class="text-center">{{ $t("label.lbl_No") }}</th>
                  <!-- 便 -->
                  <th class="text-center">{{ $t("label.lbl_transportNo") }}</th>
                  <!-- 乗務員 -->
                  <th>{{ $t("label.lbl_driver") }}</th>
                  <!-- 拠点名 -->
                  <th>{{ $t("label.lbl_baseName") }}</th>
                  <!-- 出発日時(計画) -->
                  <th class="text-center">{{ $t("label.lbl_departureScheduleDate") }}</th>
                  <!-- 出発日時(実績) -->
                  <th class="text-center">{{ $t("label.lbl_departureActualDate") }}</th>
                  <!-- 到着日時(計画) -->
                  <th class="text-center">{{ $t("label.lbl_reachScheduleDate") }}</th>
                  <!-- 到着日時(実績) -->
                  <th class="text-center">{{ $t("label.lbl_reachActualDate") }}</th>
                  <th>{{ $t("label.lbl_status") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="od in operationDetailList" :key="od.no" :class="isDelayedDetailRow(od)">
                  <!-- No -->
                  <td class="text-center">{{ od.no }}</td>
                  <!-- 便 -->
                  <td class="text-center">{{ od.transport }}</td>
                  <!-- 乗務員 -->
                  <td>{{ od.driver }}</td>
                  <!-- 拠点名 -->
                  <td>{{ od.baseName }}</td>
                  <!-- 出発日時(計画) -->
                  <td class="text-center">{{ od.departureScheduleDate }}</td>
                  <!-- 出発日時(実績) -->
                  <td class="text-center">{{ od.departureActualDate }}</td>
                  <!-- 到着日時(計画) -->
                  <td class="text-center">{{ od.arrivalScheduleDate }}</td>
                  <!-- 到着日時(実績) -->
                  <td class="text-center">{{ od.arrivalActualDate }}</td>
                  <!-- ステータス -->
                  <td>{{ od.status }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_DVP_001,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  mixins: [commonRules],
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_100: false,
    involce: "",
    dateTimeHelper: dateTimeHelper,
    // メニュー
    openMenu: null,
    // 全選択チェックボックス
    isAllSelected: false,
    // 一覧選択チェックボックス
    listCheckbox: [],
    // 編集用チェックボックス
    changeListCheckbox: [],
    listCheckboxBtn: [],
    // 運行日
    operationDate: "",
    operationDateCal: "",
    // 運行日カレンダー
    dateCalender: false,
    // 検索条件ステータス
    operationStatus: "",
    // 検索条件ステータス選択肢
    operationStatusList: [],
    // 営業所プルダウン
    officeSidSelected: "",
    getOfficeSelected: "",
    productHintOffice: "",
    officeList: [],
    // 詳細表示対象車両リスト(画面遷移用)
    carSidList: [],
    carShagoList: [],
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    itemsPerPageBtn: "100",
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },

    // 初期データ
    defaultData: [],
    // 総件数
    totalCount: 0,
    // 一覧
    inputList: [],
    searchResultPresent: false,

    // ヘッダ
    headerItems: [
      {
        text: "選択",
        value: "check",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 車両
      {
        text: i18n.tc("label.lbl_driverName"),
        value: "carSid",
        width: "10%",
        align: "center",
        sortable: false,
      },
      // 便一覧
      {
        text: i18n.tc("label.lbl_transportList"),
        value: "operationStatusList",
        width: "auto",
        align: "center",
        sortable: false,
      },
    ],

    // 運行詳細ダイアログ
    operationDetailDialog: false,

    // 運行詳細ダイアログ 表示データ
    operationDetailList: [],

    // 入力チェック
    rules: {
      yyyymmdd: (value) =>
        (dateTimeHelper.validDate(commonUtil.zen2han(value), "/") &&
          dateTimeHelper.isDate(value)) ||
        i18n.tc("check.chk_inputDate"),
    },

    // 検索パラメータ
    searchParam: undefined,

    // 警告メッセージ表示
    showWarningMessage: false,
    warningMessage: "",
  }),

  created() {
    // 詳細画面から戻り遷移した場合の検索条件
    this.searchParam = this.$route.params.searchParam;
  },

  methods: {
    /**
     * 初期値
     */
    init() {
      // // セッション情報ダミー処理
      // sessionStorage.setItem("userInfo.userName", "藤沢所長");
      // sessionStorage.setItem("sales_office_sid", "2100000999");
      // // ダミーレスポンス
      // this.$httpClient.secureGet = function (url, config) {
      //   let result = [];
      //   if (url == appConfig.API_URL.MST_BIZ_BASE_SEARCH) {
      //     result = [
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2100000050",
      //         baseCode: "001",
      //         baseOwnerType: "2",
      //         baseName: "日高営業所（テスト用）",
      //         baseNameKana: "日高営業所（テスト用）",
      //         baseShortName: "日高営業所（テスト用）",
      //         baseShortNameKana: null,
      //         postCode: "3501213",
      //         address1: "埼玉県",
      //         address2: "日高市高萩995-1",
      //         telephoneNumber: "042-985-1972",
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-07-23 14:18:00",
      //       },
      //       {
      //         customerCode: "4400710",
      //         baseSid: "2100000999",
      //         baseCode: "001",
      //         baseOwnerType: "2",
      //         baseName: "日高営業所999用",
      //         baseNameKana: "日高営業所",
      //         baseShortName: "日高営業所",
      //         baseShortNameKana: null,
      //         postCode: "3501213",
      //         address1: "埼玉県",
      //         address2: "日高市高萩995-1",
      //         telephoneNumber: "999-9991",
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-07-16 00:00:00",
      //       },
      //       {
      //         customerCode: "4400710",
      //         baseSid: "2400000001",
      //         baseCode: "001",
      //         baseOwnerType: "2",
      //         baseName: "テスト営業所（表示確認用）",
      //         baseNameKana: "テスト営業所（表示確認用）",
      //         baseShortName: "テスト営業所（表示確認用）",
      //         baseShortNameKana: null,
      //         postCode: "3501213",
      //         address1: "埼玉県",
      //         address2: "日高市高萩995-1",
      //         telephoneNumber: "042-999-8745",
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-07-16 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000001",
      //         baseCode: "001",
      //         baseOwnerType: "2",
      //         baseName: "大河原営業所",
      //         baseNameKana: null,
      //         baseShortName: "大河原",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000002",
      //         baseCode: "002",
      //         baseOwnerType: "2",
      //         baseName: "柏原営業所",
      //         baseNameKana: null,
      //         baseShortName: "柏原",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000003",
      //         baseCode: "003",
      //         baseOwnerType: "2",
      //         baseName: "鈴鹿営業所",
      //         baseNameKana: null,
      //         baseShortName: "鈴鹿C",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000004",
      //         baseCode: "004",
      //         baseOwnerType: "2",
      //         baseName: "大津営業所",
      //         baseNameKana: null,
      //         baseShortName: "大津",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000005",
      //         baseCode: "005",
      //         baseOwnerType: "2",
      //         baseName: "真岡営業所",
      //         baseNameKana: null,
      //         baseShortName: "真岡",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000007",
      //         baseCode: "007",
      //         baseOwnerType: "2",
      //         baseName: "広島営業所",
      //         baseNameKana: null,
      //         baseShortName: "広島",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000008",
      //         baseCode: "008",
      //         baseOwnerType: "2",
      //         baseName: "北郡山営業所",
      //         baseNameKana: null,
      //         baseShortName: "北郡山",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000009",
      //         baseCode: "009",
      //         baseOwnerType: "2",
      //         baseName: "太田営業所",
      //         baseNameKana: null,
      //         baseShortName: "太田",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000010",
      //         baseCode: "010",
      //         baseOwnerType: "2",
      //         baseName: "浜松営業所",
      //         baseNameKana: null,
      //         baseShortName: "浜松",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000011",
      //         baseCode: "011",
      //         baseOwnerType: "2",
      //         baseName: "神戸営業所",
      //         baseNameKana: null,
      //         baseShortName: "神戸",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000012",
      //         baseCode: "012",
      //         baseOwnerType: "2",
      //         baseName: "岡山営業所",
      //         baseNameKana: null,
      //         baseShortName: "岡山",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000013",
      //         baseCode: "013",
      //         baseOwnerType: "2",
      //         baseName: "門司営業所",
      //         baseNameKana: null,
      //         baseShortName: "門司",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "2410000014",
      //         baseCode: "014",
      //         baseOwnerType: "2",
      //         baseName: "角田営業所",
      //         baseNameKana: null,
      //         baseShortName: "角田",
      //         baseShortNameKana: null,
      //         postCode: "0000000",
      //         address1: "0",
      //         address2: "0",
      //         telephoneNumber: null,
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-11-20 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "9999999995",
      //         baseCode: "999",
      //         baseOwnerType: "2",
      //         baseName: "999営業所",
      //         baseNameKana: "999営業所",
      //         baseShortName: "999営業所",
      //         baseShortNameKana: null,
      //         postCode: "9999993",
      //         address1: "テスト県",
      //         address2: "テスト市テスト1-3",
      //         telephoneNumber: "999-9993",
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-07-16 00:00:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "8800000001",
      //         baseCode: "A1234567891234567890123456789012345678901234567890",
      //         baseOwnerType: "2",
      //         baseName:
      //           "日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日高営業所（テスト用）日",
      //         baseNameKana: "日高営業所（テスト用）",
      //         baseShortName: "日高営業所（テスト用）",
      //         baseShortNameKana: null,
      //         postCode: "3501213",
      //         address1: "埼玉県",
      //         address2: "日高市高萩995-1",
      //         telephoneNumber: "0420010-98533-197244",
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-07-23 14:18:00",
      //       },
      //       {
      //         customerCode: "1000000",
      //         baseSid: "8800000002",
      //         baseCode: "A1234567891234567890123456789012345678901234567890",
      //         baseOwnerType: "2",
      //         baseName:
      //           "VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名横浜VC東名",
      //         baseNameKana: "VC東名横浜",
      //         baseShortName: "VC東名横浜",
      //         baseShortNameKana: null,
      //         postCode: "1940005",
      //         address1:
      //           "神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川神奈川県",
      //         address2:
      //           "日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高日高市高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩高萩995-1",
      //         telephoneNumber: "0423310-97733-166244",
      //         telephoneNumber2: null,
      //         faxNumber: null,
      //         updateDatetime: "2024-07-23 14:18:00",
      //       },
      //     ];
      //     return new Promise((resolve) => {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: result.length,
      //           },
      //           resIdv: {
      //             baseInfoList: result,
      //           },
      //         },
      //       });
      //     });
      //   }
      //   if (url == appConfig.API_URL.BIZ_TRANSPORT_OPARATION_STATUS_LIST) {
      //     const params = config.params;
      //     if (params.officeSid == "2410000009" /* 太田営業所 */) {
      //       return new Promise((resolve, reject) => {
      //         reject("テスト用エラーメッセージ１");
      //       });
      //     }
      //     if (params.officeSid == "2410000008" /* 北郡山営業所 */) {
      //       return new Promise((resolve) => {
      //         resolve({
      //           data: {
      //             resCom: {
      //               resComStatus: "Success",
      //               resComCode: "999",
      //               resComCount: 8,
      //               resComMessage: "テスト用エラーメッセージ２",
      //             },
      //           },
      //         });
      //       });
      //     }
      //     let vehicleOperationList = [
      //       {
      //         carSid: "c001",
      //         carShago: "s001",
      //         operationBinList: [
      //           {
      //             transportSid: "t0101",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //           {
      //             transportSid: "t0102",
      //             binStatusDiv: "1",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //               {
      //                 operationStatusDiv: 0,
      //               },
      //             ],
      //           },
      //           {
      //             transportSid: "t0103",
      //             binStatusDiv: "0",
      //             binDelayDiv: 1,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 0,
      //               },
      //               {
      //                 operationStatusDiv: 0,
      //               },
      //               {
      //                 operationStatusDiv: 0,
      //               },
      //             ],
      //           },
      //         ],
      //       },
      //       {
      //         carSid: "c002",
      //         carShago: "s002",
      //         operationBinList: [
      //           {
      //             transportSid: "t0201",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 0,
      //               },
      //               {
      //                 operationStatusDiv: 0,
      //               },
      //             ],
      //           },
      //         ],
      //       },
      //       {
      //         carSid: "c003",
      //         carShago: "s003",
      //         operationBinList: [
      //           {
      //             transportSid: "t0301",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //           {
      //             transportSid: "t0302",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //           {
      //             transportSid: "t0303",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //           {
      //             transportSid: "t0304",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //           {
      //             transportSid: "t0305",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //           {
      //             transportSid: "t0305",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //         ],
      //       },
      //       {
      //         carSid: "c004",
      //         carShago: "s004",
      //         operationBinList: [
      //           {
      //             transportSid: "t0401",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //             ],
      //           },
      //           {
      //             transportSid: "t0402",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 0,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //           {
      //             transportSid: "t0403",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //           {
      //             transportSid: "t0404",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //           {
      //             transportSid: "t0405",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //           {
      //             transportSid: "t0405",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //         ],
      //       },
      //     ];
      //     for (let i = 5; i <= 600; i++) {
      //       let no = ("000" + i).slice(-3);
      //       vehicleOperationList.push({
      //         carSid: "c" + no,
      //         carShago: "s" + no,
      //         operationBinList: [
      //           {
      //             transportSid: "t" + no + "01",
      //             binStatusDiv: "2",
      //             binDelayDiv: 0,
      //             operationStatusList: [
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 1,
      //               },
      //               {
      //                 operationStatusDiv: 2,
      //               },
      //             ],
      //           },
      //         ],
      //       });
      //     }
      //     if (params.officeSid == "2410000007" /* 広島営業所 */) {
      //       // 異常値対応確認 対処しないと運行状況に「0/-1 完了」と表示されるパターン
      //       vehicleOperationList = [
      //         {
      //           carSid: "0024080103",
      //           carShago: "FSICR01",
      //           operationBinList: [
      //             {
      //               transportSid: null,
      //               binStatusDiv: "0",
      //               binDelayDiv: "0",
      //               operationStatusList: [
      //                 {
      //                   operationStatusDiv: null,
      //                 },
      //               ],
      //             },
      //           ],
      //         },
      //       ];
      //     }
      //     if (params.officeSid == "2410000005" /* 真岡営業所 */) {
      //       // 異常値対応確認 対処しないと運行状況のプログレスバー位置が不定になるパターン
      //       vehicleOperationList = [
      //         {
      //           carSid: "0024080103",
      //           carShago: "FSICR01",
      //           operationBinList: [
      //             {
      //               transportSid: null,
      //               binStatusDiv: "0",
      //               binDelayDiv: "0",
      //               operationStatusList: [
      //                 {
      //                   operationStatusDiv: 0,
      //                 },
      //                 {
      //                   operationStatusDiv: 0,
      //                 },
      //               ],
      //             },
      //           ],
      //         },
      //       ];
      //     }
      //     if (params.officeSid == "2410000004" /* 大津営業所 */) {
      //       vehicleOperationList = [];
      //     }
      //     let pageStart = params.reqComPageLimit * (params.reqComPageIndex - 1);
      //     let pageEnd = pageStart + params.reqComPageLimit;
      //     if (pageEnd >= vehicleOperationList.length) {
      //       pageEnd = vehicleOperationList.length;
      //     }
      //     let pageCount = Math.ceil(vehicleOperationList.length / params.reqComPageLimit);
      //     // 社号別運行状況一覧API ダミーレスポンス
      //     return new Promise((resolve) => {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 8,
      //             resComPagination: {
      //               pageLimit: params.reqComPageLimit,
      //               pageIndex: params.reqComPageIndex,
      //               totalRecord: vehicleOperationList.length,
      //               totalPage: pageCount,
      //             },
      //           },
      //           resIdv: {
      //             vehicleOperationList: vehicleOperationList.slice(pageStart, pageEnd),
      //           },
      //         },
      //       });
      //     });
      //   }
      //   if (url == appConfig.API_URL.BIZ_OPARATION_STATUS_VEHICLECODE) {
      //     const params = config.params;
      //     if (params.carSid == "c005" /* 5行目 */) {
      //       return new Promise((resolve, reject) => {
      //         reject("テスト用エラーメッセージ１");
      //       });
      //     }
      //     if (params.carSid == "c006" /* 6行目 */) {
      //       return new Promise((resolve) => {
      //         resolve({
      //           data: {
      //             resCom: {
      //               resComStatus: "Success",
      //               resComCode: "999",
      //               resComCount: 8,
      //               resComMessage: "テスト用エラーメッセージ２",
      //             },
      //           },
      //         });
      //       });
      //     }
      //     let operationStatusList = [
      //       {
      //         transport: "1",
      //         driver: "田中太郎",
      //         baseName: "XX営業所",
      //         departureScheduleDate: "11/06(水) 09:00:00",
      //         departureActualDate: "11/06(水) 09:00:00",
      //         arrivalScheduleDate: "-",
      //         arrivalActualDate: "-",
      //         status: "集荷・配達済み",
      //         delayStatus: "0",
      //       },
      //       {
      //         driver: "田中太郎",
      //         transport: "1",
      //         baseName: "YY営業所",
      //         departureScheduleDate: "-",
      //         departureActualDate: "11/06(水) 10:05:00",
      //         arrivalScheduleDate: "11/06(水) 09:00:00",
      //         arrivalActualDate: "11/06(水) 09:00:00",
      //         status: "集荷・配達済み",
      //         delayStatus: "0",
      //       },
      //       {
      //         transport: "1",
      //         driver: "田中太郎",
      //         baseName: "ZZ営業所",
      //         departureScheduleDate: "-",
      //         departureActualDate: "11/06(水) 11:35:00",
      //         arrivalScheduleDate: "11/06(水) 11:00:00",
      //         arrivalActualDate: "11/06(水) 11:05:00",
      //         status: "集荷・配達済み",
      //         delayStatus: "0",
      //       },
      //       {
      //         transport: "1",
      //         driver: "田中太郎",
      //         baseName: "XX営業所",
      //         departureScheduleDate: "-",
      //         departureActualDate: "-",
      //         arrivalScheduleDate: "11/06(水) 12:00:00",
      //         arrivalActualDate: "11/06(水) 12:05:00",
      //         status: "集荷・配達済み",
      //         delayStatus: "1",
      //       },
      //       {
      //         transport: "2",
      //         driver: "田中太郎",
      //         baseName: "XX営業所",
      //         departureScheduleDate: "-",
      //         departureActualDate: "11/06(水) 15:00:00",
      //         arrivalScheduleDate: "-",
      //         arrivalActualDate: "-",
      //         status: "未集荷・未配達",
      //         delayStatus: "1",
      //       },
      //       {
      //         driver: "田中太郎",
      //         transport: "2",
      //         baseName: "YY営業所",
      //         departureScheduleDate: "-",
      //         departureActualDate: "-",
      //         arrivalScheduleDate: "11/06(水) 15:00:00",
      //         arrivalActualDate: "-",
      //         status: "未集荷・未配達",
      //         delayStatus: "0",
      //       },
      //       {
      //         transport: "2",
      //         driver: "田中太郎",
      //         baseName: "ZZ営業所",
      //         departureScheduleDate: "-",
      //         departureActualDate: "-",
      //         arrivalScheduleDate: "11/06(水) 17:00:00",
      //         arrivalActualDate: "-",
      //         status: "未集荷・未配達",
      //         delayStatus: "0",
      //       },
      //       {
      //         transport: "2",
      //         driver: "田中太郎",
      //         baseName: "AA営業所",
      //         departureScheduleDate: "-",
      //         departureActualDate: "-",
      //         arrivalScheduleDate: "11/06(水) 17:45:00",
      //         arrivalActualDate: "-",
      //         status: "未集荷・未配達",
      //         delayStatus: "0",
      //       },
      //       {
      //         transport: "2",
      //         driver: "田中太郎",
      //         baseName: "BB営業所",
      //         departureScheduleDate: "-",
      //         departureActualDate: "-",
      //         arrivalScheduleDate: "11/06(水) 18:00:00",
      //         arrivalActualDate: "-",
      //         status: "未集荷・未配達",
      //         delayStatus: "0",
      //       },
      //       {
      //         transport: "2",
      //         driver: "田中太郎",
      //         baseName: "XX営業所",
      //         departureScheduleDate: "-",
      //         departureActualDate: "-",
      //         arrivalScheduleDate: "11/06(水) 18:45:00",
      //         arrivalActualDate: "-",
      //         status: "未集荷・未配達",
      //         delayStatus: "0",
      //       },
      //       {
      //         transport: "3",
      //         driver: "田中太郎",
      //         baseName: "XX営業所",
      //         departureScheduleDate: "-",
      //         departureActualDate: "-",
      //         arrivalScheduleDate: "-",
      //         arrivalActualDate: "-",
      //         status: "未集荷・未配達",
      //         delayStatus: "0",
      //       },
      //     ];
      //     // 社号別運行状況一覧APIダミーレスポンス
      //     return new Promise((resolve) => {
      //       resolve({
      //         data: {
      //           resCom: {
      //             resComStatus: "Success",
      //             resComCode: "000",
      //             resComCount: 19,
      //           },
      //           resIdv: {
      //             operationStatusList: operationStatusList,
      //           },
      //         },
      //       });
      //     });
      //   }
      // };

      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      this.getMstCode();
      if (this.searchParam) {
        // 詳細画面からの戻り遷移の場合
        this.operationDate = this.searchParam.operationDate;
        this.officeSidSelected = this.searchParam.officeSidSelected;
        this.operationStatus = this.searchParam.operationStatus;
        this.getApiList(true, true);
      } else {
        // 通常遷移の場合
        this.operationDate = dateTimeHelper.convertJST();
        this.officeSidSelected = sessionStorage.getItem("sales_office_sid");
        this.operationStatus = "";
        this.inputList = [];
        this.searchResultPresent = false;
      }
      this.operationDateCal = dateTimeHelper.dateCalc(this.operationDate);
      this.productHintOffice = this.officeSidSelected;
      this.getCustomInfo();
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.getApiList(false, false);
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false, false);
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // ステータスの選択肢設定
      let list = [{ text: "", name: "", value: "" }];
      list.push({
        text: this.$t("label.lbl_unPickupUnDelivered"),
        value: "0",
        name: this.$t("label.lbl_unPickupUnDelivered"),
      });
      list.push({
        text: this.$t("label.lbl_duringPickupDelivery"),
        value: "1",
        name: this.$t("label.lbl_duringPickupDelivery"),
      });
      list.push({
        text: this.$t("label.lbl_pickupDeliveryComplete"),
        value: "2",
        name: this.$t("label.lbl_pickupDeliveryComplete"),
      });
      this.operationStatusList = list;
      this.loadingCounter = 0;
    },

    // 営業所プルダウン
    getCustomInfo() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const params = {};

      // 拠点管理種別（2=営業所を取得）
      params.baseOwnerType = "2";
      // 有効期限区分（0=有効期限内データを取得）
      params.validDateKbn = "0";
      // 自拠点区分（1=自拠点を含む）
      params.selfEstablishedLocationsKbn = "1";

      const pickupListTmp = getParameter.getTmsBizBaseMst(params);
      Promise.all([pickupListTmp])
        .then((result) => {
          let filtered = result[0].filter((v) => v.value == this.officeSidSelected);
          if (filtered.length > 0) {
            this.officeList = filtered;
          } else {
            this.officeList = result[0];
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 社号別運行状況一覧画面：全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckBox = [];
        this.listCheckBox = true;
        // 編集用リストを初期化
        this.changeListCheckbox = [];
        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
          this.changeListCheckbox.push(this.inputList[i]);
          this.listCheckboxBtn.push(this.inputList[i]);
        }
      } else {
        this.listCheckBox = [];
        this.listCheckBox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;

          // 編集用リストを初期化
          this.changeListCheckbox = [];
          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
        }
        this.isAllSelected = false;
      }
    },

    /**
     * 検索ボタン押下処理
     */
    searchButtonClick() {
      // 入力エラーがある場合は検索しない
      if (!this.$refs.form.validate()) {
        return;
      }
      this.getApiList(true, true);
    },

    // 検索と結果表示
    getApiList(checkBoxFlag, isSearchConditionUpdate) {
      // // 検索結果表示中でない場合
      // if (this.searchResultPresent == false) {
      //   // 検索結果の表示方法が変わっただけの場合は何もしない
      //   if (isSearchConditionUpdate == false) {
      //     return;
      //   }
      // }

      // メッセージ初期化
      this.alertMessage = "";
      this.alertSupplierMessage = "";
      this.createalertMessage = "";

      // 初期検索かページング検索かを判断
      if (checkBoxFlag) {
        this.isAllSelected = false;
      }

      if (isSearchConditionUpdate) {
        // 検索条件を保存
        this.searchParam = {
          operationDate: this.operationDate,
          operationStatus: this.operationStatus,
          officeSidSelected: this.officeSidSelected,
        };
      }

      // ローディング画面表示ON
      this.loadingCounter = 1;

      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 運行日
      this.operationDateUTC = dateTimeHelper.convertUTC(this.operationDate);
      config.params.operationDate = this.operationDateUTC;
      // 営業所SID
      config.params.officeSid = this.officeSidSelected;
      // ステータス
      config.params.status = this.operationStatus;

      // ページャー処理
      if ((this.page >= 2 && this.inputList.length == 0) || isSearchConditionUpdate) {
        config.params.reqComPageIndex = "1";
        this.page = 1;
      } else {
        config.params.reqComPageIndex = this.page;
      }
      // ページング
      config.params.reqComPaginationFlg = "1";
      config.params.reqComPageLimit = this.itemsPerPage;

      return new Promise((resolve) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_TRANSPORT_OPARATION_STATUS_LIST, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              this.pageCount = jsonData.resCom.resComPagination.totalPage;
              this.totalCount = jsonData.resCom.resComPagination.totalRecord;
              const dispatchVehicleList = [];
              // 便一覧を取得
              jsonData.resIdv.vehicleOperationList.forEach((vehicle) => {
                vehicle.operationBinList.forEach((operationBinData) => {
                  let nAllOps = operationBinData.operationStatusList.length - 2;
                  let nCompletedOps = 0;
                  for (let i = 1; i <= nAllOps; i++) {
                    if (this.isCompleted(operationBinData.operationStatusList, i)) {
                      nCompletedOps = i;
                    } else {
                      break;
                    }
                  }
                  let progressValue;
                  let progressText;
                  if (nAllOps <= 0) {
                    // 異常値
                    progressValue = 0;
                    progressText = "0/0";
                  } else {
                    progressValue = (nCompletedOps / nAllOps) * 100;
                    progressText = nCompletedOps + "/" + nAllOps;
                  }
                  operationBinData.operationProgressValue = progressValue;
                  operationBinData.operationProgressText =
                    progressText + " " + i18n.tc("label.lbl_completion");
                });

                dispatchVehicleList.push({
                  // 車両SID
                  carSid: vehicle.carSid,
                  // 車両
                  carShago: vehicle.carShago,
                  // 便情報一覧
                  operationStatusList: vehicle.operationBinList,
                  // チェックボックス
                  check: false,
                });
              });
              this.inputList = dispatchVehicleList;

              if (!checkBoxFlag) {
                // 検索ボタン以外で検索処理が走った場合、元々チェックしていたレコードにチェックをいれる
                for (var k = 0; k < this.inputList.length; k++) {
                  for (var j = 0; j < this.listCheckboxBtn.length; j++) {
                    if (this.inputList[k].carSid == this.listCheckboxBtn[j].carSid) {
                      this.inputList[k].check = true;
                    }
                  }
                }
              } else {
                // 検索ボタン押下時は、チェックボックスを詰めていたListを初期化する
                this.listCheckboxBtn.length = 0;
                // 検索結果表示中であることを記憶する
                this.searchResultPresent = true;
              }

              // ページング検索の場合の全選択チェックボックス処理
              if (this.isAllSelected) {
                this.isAllSelected = true;
                for (var i = 0; i < this.inputList.length; i++) {
                  this.inputList[i].check = true;
                }
              }
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 便の色切替
     */
    getBackgroundColor(binStatusDiv) {
      switch (binStatusDiv ?? "0") {
        case "0":
          return "red accent-1";
        case "1":
          return "blue accent-2";
        case "2":
          return "grey darken-1";
        default:
          return "red accent-1";
      }
    },

    /**
     * 便状況の表示テキスト切替
     */
    binStatusText(binStatusDiv) {
      switch (binStatusDiv ?? "0") {
        case "0":
          return this.$t("label.lbl_unPickupUnDelivered");
        case "1":
          return this.$t("label.lbl_duringPickupDelivery");
        case "2":
          return this.$t("label.lbl_pickupDeliveryComplete");
        default:
          return this.$t("label.lbl_unPickupUnDelivered");
      }
    },

    /* 運行状況 拠点完了判定 */
    isCompleted(operationStatusList, opIdx) {
      if (this.isLastOp(opIdx, operationStatusList)) {
        return operationStatusList[opIdx].operationStatusDiv > 0;
      }
      return operationStatusList[opIdx].operationStatusDiv == 1;
    },

    /* 運行状況 最終拠点判定 */
    isLastOp(idx, array) {
      return idx == array.length - 1;
    },

    // 運行状況詳細ダイアログ表示
    showDetailDialog(operationDate, carSid) {
      // httpClientで使用する共通IFパラメータ等を作成する。
      const config = this.$httpClient.createGetApiRequestConfig();

      // 運行日
      config.params.operationDate = operationDate;
      // 車両SID
      config.params.carSid = carSid;

      return new Promise((resolve) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_OPARATION_STATUS_VEHICLECODE, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 成功
            if (jsonData.resCom.resComCode == "000") {
              let no = 0;
              jsonData.resIdv.operationStatusList.forEach((operationDetail) => {
                operationDetail.no = ++no;
              });

              this.operationDetailList = jsonData.resIdv.operationStatusList;
              this.operationDetailDialog = true;
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.screenFlag = true;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    // 運行詳細一覧の遅延判定(行)
    isDelayedDetailRow(operationDetail) {
      if (operationDetail.delayStatus == "1") {
        return "delayed";
      }
      return "";
    },

    // 運行状況画面へ遷移
    showOperationStatus() {
      if (this.listCheckboxBtn.length == 0) {
        // 警告ダイアログ表示
        this.showWarningMessage = true;
        this.warningMessage = messsageUtil.getMessage("P-EXT-003_001_E");
        return;
      }

      // 遷移時に渡す社号を設定
      this.carSidList = [];
      this.carSHagoList = [];
      for (var i = 0; i < this.listCheckboxBtn.length; i++) {
        this.carSidList.push(this.listCheckboxBtn[i].carSid);
        this.carShagoList.push(this.listCheckboxBtn[i].carShago);
      }
      this.$router.push({
        name: appConfig.SCREEN_ID.P_OPM_002,
        params: {
          // 運行日と車両SID、社号のリスト
          operationDate: this.searchParam.operationDate,
          carSidList: this.carSidList,
          carShagoList: this.carShagoList,
          // 戻り遷移用 検索条件
          searchParam: this.searchParam,
        },
      });
    },

    /**
     * 警告ダイアログ OKボタン押下
     */
    closeWarningMessage() {
      this.showWarningMessage = false;
    },

    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合

        // 編集用リスト
        this.changeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckboxBtn.push(item);
      } else {
        // チェックを外した場合

        // 外したレコードの社号を取得
        let inputListOutlist = this.inputList[index].carShago;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.changeListCheckbox.length; i++) {
          if (this.changeListCheckbox[i].carShago != inputListOutlist) {
            this.listCheckboxBtn.push(this.changeListCheckbox[i]);
          }
        }

        // 編集用リストも更新
        this.changeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.changeListCheckbox.push(this.listCheckboxBtn[j]);
        }
      }
      // 全選択チェックボックスとチェックボックスの整合性を合わせる
      if (this.inputList.length != this.listCheckboxBtn.length) {
        // 表示車両数とチェックされた車両数が異なる場合
        this.isAllSelected = false;
      } else if (this.inputList.length == this.listCheckboxBtn.length) {
        // 表示車両数とチェックされた車両数が一致する場合
        this.isAllSelected = true;
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 運行日付入力
     */
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        this.operationDateCal = dateTimeHelper.dateCalc(
          dateTimeHelper.convertUTC2JST(strDt.substr(0, 10))
        );
      } else {
        this.operationDateCal = null;
      }
    },

    /**
     * 運行日付を1日遡る
     */
    prevDate() {
      if (this.operationDateCal == null) {
        return;
      }
      let date = new Date(this.operationDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.operationDateCal != toDate) {
        this.operationDateCal = toDate;
      } else {
        this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() - 1));
      }
    },

    /**
     * 運行日付を1日進める
     */
    nextDate() {
      if (this.operationDateCal == null) {
        return;
      }
      let date = new Date(this.operationDateCal);
      let toDate = dateTimeHelper.dateCalc(date.setDate(date.getDate()));
      if (this.operationDateCal != toDate) {
        this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 2));
      } else {
        this.operationDateCal = dateTimeHelper.dateCalc(date.setDate(date.getDate() + 1));
      }
    },

    /**
     * 営業所リストボックス取得処理
     */
    setOffice() {
      for (var i = 0; i < this.officeList.length; i++) {
        if (this.officeList[i].value == this.officeSidSelected) {
          return this.officeList[i].name;
        }
      }
    },

    /**
     * 営業所 プルダウンチェンジイベント
     */
    changeOffice(value) {
      let val = this.officeList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintOffice = val.value;
        this.getOfficeSelected = val.value;
      }
      this.officeList = this.officeList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintOffice = "";
      } else {
        // 処理なし
      }
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.title = appConfig.DIALOG.confirm;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.cancelBtnFlg = true;
      this.confirmDialog.outsideClickNotCloseFlg = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },

    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 遷移情報クリア
      this.searchParam = undefined;
      // 変更検知用の設定
      this.operationDateCal = "";
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },

    operationDateCal: {
      handler(val) {
        this.operationDate = this.formatDate(val);
      },
      deep: true,
    },

    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false, false);
    },

    /**
     * 営業所 文字入力イベント処理
     */
    getOfficeSelected(text) {
      if (text ?? false) {
        let officeList = this.officeList;
        let office = officeList.find((v) => v.text == text || v.value == text);
        let val = office ? office.value : text;

        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintOffice) {
          return;
        }

        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          const params = {};
          // 拠点管理種別（2=営業所を取得）
          params.baseOwnerType = "2";
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";
          // 拠点コード
          params.baseCode = val;

          const officeListTmp = getParameter.getTmsBizBaseMst(params);
          Promise.all([officeListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.officeList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.officeList.length = 0;
          this.officeList = [];
        }
      }
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}

.search-row {
  padding-left: 0.7rem;
}

.txt-center {
  margin: auto;
}

.add-textbox-num {
  width: 16rem;
  padding: 20px 0 0 10px;
}
.validation {
  color: red;
  text-align: center;
  margin-bottom: 3%;
}

// 検索結果表示
.searchResult#listData {
  overflow-x: scroll;
  &.v-data-table--fixed-header ::v-deep {
    th:nth-child(2) {
      border-right: 3px lightgrey;
      border-right-style: solid;
    }

    //テーブルデータ部分
    td:nth-child(2) {
      border-right: 3px lightgrey;
      border-right-style: solid;
    }
    td:nth-child(3) {
      padding-left: 0.7%;
      padding-right: 0%;
    }
  }
}

/* 便パネル */
div.transport-info-box.v-card {
  width: 200px;
  height: 49px;
  float: left;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

/* 便ステータス */
.transport-info-box .binStatus {
  font-weight: bold;
  margin: 0;
}

/* 便の遅延表示 */
.transport-info-box .dispatchBinDelayIcon {
  position: absolute;
  font-size: 19px;
  top: 1px;
  left: 3px;
}

/* 運行状況 */
.transport-info-box .operationStatus {
  width: 170px; /* 運行状況全体の幅 */
  height: 15px; /* 運行状況全体の高さ */
  margin-top: 1px; /* 便ステータスとの間隔 */
  margin-bottom: 2px;
  font-size: 12px;
  :deep(.v-progress-linear__content) {
    color: white;
  }
}

/* 運行予定実績表示ダイアログの一覧表 */
.operationDetailList.v-data-table.v-data-table--fixed-header {
  /* 表の高さ */
  :deep(.v-data-table__wrapper) {
    height: 525px;
  }
  /* タイトル行の背景色 */
  thead th {
    background-color: #effad9;
  }
  /* 遅延行の背景色 */
  tr.delayed td {
    background-color: #ff6262;
  }
}
</style>
