<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_costMasterDetail" />
      <v-container fluid>
        <v-row>
          <!-- 変動費詳細 -->
          <v-col cols="6">
            <div>
              <p class="cost-div-name">{{ $t("label.lbl_variableCosts") }}</p>
              <table border="2" class="detail-table">
                <thead>
                  <tr>
                    <th rowspan="2">
                      <p>{{ $t("label.lbl_totalVariableCosts") }}</p>
                      <p class="detail-th-sub">{{ $t("label.lbl_per1km") }}</p>
                    </th>
                    <th valign="top">{{ $t("label.lbl_actualVehicleRate") }}</th>
                    <th rowspan="2">
                      <p>{{ $t("label.lbl_actualVariableCosts") }}</p>
                      <p class="detail-th-sub">{{ $t("label.lbl_per1km") }}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td :class="getVariableCostTdClass()">{{ actVariableCost }}</td>
                    <td>
                      <v-text-field
                        single-line
                        outlined
                        suffix="%"
                        class="input-number"
                        @change="(event) => changeLoadFactor(event)"
                        dense
                        v-model="loadFactor"
                        :rules="[
                          rules.isNumber,
                          rules.inputRequired,
                          rules.inputNumber_3_2,
                          rules.isRangeCheck_0_100,
                        ]"
                        maxLength="6"
                      ></v-text-field>
                    </td>
                    <td :class="getVariableCostTdClass()">{{ actVariableCostTotal }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-col>
          <!-- 固定費詳細 -->
          <v-col cols="6">
            <div>
              <p class="cost-div-name">{{ $t("label.lbl_fixedCosts") }}</p>
              <table
                border="2"
                style="
                  width: 100%;
                  border-collapse: collapse;
                  border-color: black;
                  table-layout: fixed;
                "
                class="detail-table"
              >
                <thead>
                  <tr>
                    <th rowspan="2">
                      <p>{{ $t("label.lbl_totalFixedCosts") }}</p>
                      <p class="detail-th-sub">{{ $t("label.lbl_per1km") }}</p>
                    </th>
                    <th valign="top">{{ $t("label.lbl_occupancyRate") }}</th>
                    <th rowspan="2">
                      <p>{{ $t("label.lbl_actualFixedCosts") }}</p>
                      <p class="detail-th-sub">{{ $t("label.lbl_per1km") }}</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td :class="getFixedCostTdClass()">{{ actFixedCost }}</td>
                    <td>
                      <v-text-field
                        single-line
                        outlined
                        suffix="%"
                        class="input-number"
                        dense
                        v-model="utilizationRate"
                        @change="(event) => changeUtilizationRate(event)"
                        :rules="[
                          rules.isNumber,
                          rules.inputRequired,
                          rules.inputNumber_3_2,
                          rules.isRangeCheck_0_100,
                        ]"
                        maxLength="6"
                      ></v-text-field>
                    </td>
                    <td :class="getFixedCostTdClass()">{{ actFixedCostTotal }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <div class="search-row-exeBtn">
            <!--戻るボタン領域-->
            <div class="btn-search-area" style="float: left">
              <!--検索ボタン-->
              <v-btn color="primary" id="get-search" class="other-btn" @click="backPage()">{{
                $t("btn.btn_back")
              }}</v-btn>
            </div>

            <!-- コピー/登録 -->
            <div style="float: right; display: flex">
              <v-autocomplete
                dense
                v-model="copyTargetVehicle"
                class="txt-single"
                :label="$t('label.lbl_copyTargetVehicle')"
                :items="copyTargetVehicleList"
                style="width: 400px"
                persistent-hint
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data" style="">
                  <span class="auto-txt-size">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
              <!-- 車両データコピーボタン -->
              <v-btn color="primary" class="other-btn" @click="copyTargetVehicleData()"
                >{{ $t("btn.btn_copy") }}
              </v-btn>
              <!-- 登録ボタン -->
              <v-btn
                color="primary"
                class="api-btn"
                @click="displayRegistrationConfirmationDialog()"
                >{{ $t("btn.btn_insert") }}</v-btn
              >
            </div>
          </div>
        </v-row>
        <v-row>
          <v-col>
            <!-- 内訳トグル -->
            <div style="float: left">
              <v-btn-toggle
                v-model="toggleCostDiv"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  mandatory
                  class="cost-btn"
                  v-for="item in toggleCostDivList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- 詳細データテーブル -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid class="main-area">
          <v-data-table
            id="costData"
            :fixed-header="true"
            :headers="headers"
            :items="items"
            :ripple="false"
            disable-filtering
            :hide-default-footer="true"
            :items-per-page="50"
            height="480px"
          >
            <template v-slot:[`item`]="{ item, index }">
              <tr :class="getCostTrClass(item.costItemType)">
                <td>{{ item.costItemCd }}</td>
                <td>{{ item.costItemName }}</td>
                <td>{{ item.costItemDetailCd }}</td>
                <td>{{ item.costItemDetailName }}</td>
                <td>
                  <v-text-field
                    single-line
                    outlined
                    class="input-number"
                    dense
                    v-model="item.unitPrice"
                    v-if="!item.costItemType"
                    maxLength="14"
                    @change="changeUnitPrice(item, index)"
                    :rules="[rules.isNumber, rules.inputRequired, rules.inputNumber_9_2]"
                  ></v-text-field>
                </td>
                <td>{{ item.unitName }}</td>
                <td class="text-center">
                  <v-btn small @click="editExpireDate(item, index)" text v-if="!item.costItemType">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </td>
                <td class="text-right">{{ item.cost }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-container>
      </v-form>

      <!-- 戻るボタンを押下時出現ダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- 有効期限設定モーダル -->
      <v-dialog v-model="isOpenEditExpireDateDialog" :max-width="1300" persistent>
        <editExpireDateDialog
          :isOpenDetailDialog.sync="isOpenEditExpireDateDialog"
          :selectedExpireDateList="selectedExpireDateList"
          :viewDataList="items"
          :selectedIndex="selectedIndex"
          :_deleteList="deleteList"
          v-on:child-event="parentMethod"
        />
      </v-dialog>

      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :screenFlag="confirmDialog.screenFlag"
        :changeFlag="confirmDialog.changeFlag"
      />

      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :changeFlag="infoDialog.changeFlag"
        :homePageFlag="infoDialog.homePageFlag"
        :mstCostListFlg="infoDialog.mstCostListFlg"
        :mstCostDetailFlg="infoDialog.mstCostDetailFlg"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
        :okAction="infoDialog.okAction"
      />

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
    </v-sheet>
  </div>
</template>
<script>
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import editExpireDateDialog from "./MstCostEditExpireDate";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import { i18n } from "../../lang/lang.js";
export default {
  name: appConfig.SCREEN_ID.P_CLA_002,
  components: {
    Loading,
    sideMenu,
    NavBar,
    editExpireDateDialog,
    ConfirmDialog,
    SimpleDialog,
  },
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    // 初期データ
    defaultData: [],
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // メニュー
    openMenu: null,
    // 実車率
    loadFactor: "",
    // 稼働率
    utilizationRate: "",
    // ボタントグル
    toggleCostDiv: "01",
    toggleCostDivList: [],
    copyTargetVehicle: "",
    copyTargetVehicleList: [],
    // 表示中の区分 (変動費/固定費)
    currentCostDiv: "",
    // データテーブル ヘッダー
    headers: [],
    // データテーブル アイテム
    items: [],
    // 車両SID
    vehicleSid: "",
    // 実車率単価マップ
    loadFactorMap: {},
    // 稼働率単価マップ
    utilizationRateMap: {},
    // ヘッダー
    baseHeaders: [
      {
        text: i18n.tc("label.lbl_costItemCd"),
        value: "costItemCd",
        width: "120px",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_costItemName"),
        value: "costItemName",
        width: "200px",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_costItemDetailCd"),
        value: "costItemDetailCd",
        width: "160px",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_costItemDetailName"),
        value: "costItemDetailName",
        width: "200px",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_unitPrice"),
        value: "unitPrice",
        width: "100px",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_unitPriceUnit"),
        value: "unitName",
        width: "100px",
        align: "left",
        sortable: false,
      },
      {
        text: i18n.tc("label.lbl_dateOfExpiry"),
        value: "validityPeriod",
        width: "40px",
        align: "center",
        sortable: false,
      },
    ],
    // 変動費ヘッダー
    variableCostHeaders: {
      text: i18n.tc("label.lbl_variableCosts"),
      value: "cost",
      width: "200px",
      align: "right",
      sortable: false,
    },
    // 固定費ヘッダー
    fixedCostHeaders: {
      text: i18n.tc("label.lbl_fixedCosts"),
      value: "cost",
      width: "200px",
      align: "right",
      sortable: false,
    },
    // バリデーションルール
    rules: {
      inputRequired: (value) => !!value || i18n.tc("check.chk_input"),
      isNumber: (value) =>
        !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNumber"),
      inputNumber_9_2: (value) => {
        let val = String(value).replaceAll(",", "");
        var check = /^([0-9]{1,9}|0)([.][0-9]{1,2})?$/g.test(val);
        if ((val ?? false) && val != "") {
          if (check == true) {
            return true;
          } else {
            return i18n.tc("check.chk_inputNumber_9-2");
          }
        } else {
          return true;
        }
      },
      inputNumber_3_2: (value) => {
        let val = String(value).replaceAll(",", "");
        var check = /^([0-9]{1,3}|0)([.][0-9]{1,2})?$/g.test(val);
        if ((val ?? false) && val != "") {
          if (check == true) {
            return true;
          } else {
            return i18n.tc("check.chk_inputNumber_3-2");
          }
        } else {
          return true;
        }
      },
      isRangeCheck_0_100: (value) => {
        if ((value ?? false) && value != "") {
          let val = Number(commonUtil.zen2han(value));
          if (0.0 > val || val > 100.0) {
            return i18n.tc("check.chk_inputNumberRange_0-100");
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
    },
    // 変動費詳細リスト
    variableCostDetails: [],
    // 固定費詳細リスト
    fixedCostDetails: [],
    // ConfirmDialog
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // SimpleDialog
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      closeFlag: false,
      outsideClickNotCloseFlg: false,
      mstCostListFlg: false,
      mstCostDetailFlg: false,
      homePageFlag: false,
      okAction: () => {},
    },
    // 有効期限設定モーダル
    isOpenEditExpireDateDialog: false,
    selectedExpireDateList: [],
    inputList: [],
    costDivList: [],
    fixedCostList: [],
    variableCostList: [],
    // 実変動費
    actVariableCost: 0,
    // 実変動費計
    actVariableCostTotal: 0,
    // 実固定費
    actFixedCost: 0,
    // 実固定費計
    actFixedCostTotal: 0,
    // 有効期限リスト
    expireDateList: [],
    selectedIndex: 0,
    deleteList: [],
    fiveInDiv: "",
    updateDialogMessage: false,
    inValidExpireDateList: [],
    backMessage: "",
    // 最大費用
    maxCost: 999999999.99,
    // 明細データをコピーしたか判定するフラグ
    isCopyItems: false,
  }),
  methods: {
    /**
     * 変動費内訳詳細表示処理
     */
    showVariableCostDetail() {
      this.headers.splice(0);
      this.headers.push(...this.baseHeaders);
      this.headers.push(this.variableCostHeaders);

      this.items.splice(0);
      this.items.push(...this.variableCostDetails);

      this.currentCostDiv = this.toggleCostDiv;
      console.log(this.currentCostDiv);
    },
    /**
     * 固定費内訳詳細表示処理
     */
    showFixedCostDetail() {
      this.headers.splice(0);
      this.headers.push(...this.baseHeaders);
      this.headers.push(this.fixedCostHeaders);

      this.items.splice(0);
      this.items.push(...this.fixedCostDetails);

      this.currentCostDiv = this.toggleCostDiv;
      console.log(this.currentCostDiv);
    },
    /**
     * 変動費内訳トグル選択判定
     */
    isVariableCostSelected() {
      return this.currentCostDiv === appConfig.COST_BREAKDOWN_DIV.VARIABLE_COST;
    },
    /**
     * 固定費内訳トグル選択判定
     */
    isFixedCostSelected() {
      return this.currentCostDiv === appConfig.COST_BREAKDOWN_DIV.FIXED_COST;
    },
    /**
     * 変動費テーブルデータレイアウトクラス取得処理
     */
    getVariableCostTdClass() {
      const classes = [];
      if (this.isVariableCostSelected()) {
        classes.push("cost-selected");
      }
      return classes;
    },
    /**
     * 固定費テーブルデータレイアウトクラス取得処理
     */
    getFixedCostTdClass() {
      const classes = [];
      if (this.isFixedCostSelected()) {
        classes.push("cost-selected");
      }
      return classes;
    },
    /**
     * 小計データレイアウトクラス取得処理
     */
    getCostTrClass(costItemType) {
      const classes = [];
      if (costItemType == "Subtotal") {
        classes.push("cost-tr-subtotal");
        return classes;
      }
      if (costItemType == "Total") {
        classes.push("cost-tr-total");
        return classes;
      }
      return classes;
    },

    /**
     * 初期起動
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      // 車両SID
      if (this.$route.params.vehicleSid) {
        this.vehicleSid = this.$route.params.vehicleSid;
      }

      // 標準原価マスタ詳細API実行
      this.executeCostMstDetailApi(this.vehicleSid);
      // 初期データ取得
      this.getInitData();
      this.showVariableCostDetail();
      this.$watch(
        () => this.toggleCostDiv,
        (newVal) => {
          this.chengeToggleCostDiv(newVal);
        }
      );
    },

    /**
     * 変動費・固定費トグル変更時処理
     */
    chengeToggleCostDiv(newVal) {
      if (newVal == appConfig.COST_BREAKDOWN_DIV.VARIABLE_COST) {
        this.showVariableCostDetail();
      } else {
        this.showFixedCostDetail();
      }
    },

    /**
     * 初期データ取得
     */
    getInitData() {
      // 変動費・固定費内訳区分
      const costBreakDownList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.COST_BREAKDOWN_DIV,
      });
      // 車両マスタ情報取得
      const vehicleList = getParameter.getVehicleMst(null);
      Promise.all([costBreakDownList, vehicleList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.toggleCostDivList = result[0];
          this.copyTargetVehicleList = result[1];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * 有効期限設定モーダルの表示
     */
    editExpireDate(item, index) {
      this.selectedExpireDateList = item.expireDateList;
      this.selectedIndex = index;
      this.isOpenEditExpireDateDialog = true;
    },

    /**
     * 標準原価マスタ詳細API実行
     */
    executeCostMstDetailApi(vehicleSid) {
      this.loadingCounter = 1;
      this.expireDateList.splice(0);
      const config = this.$httpClient.createGetApiRequestConfig();
      // 車両SID
      config.params.vehicleSid = vehicleSid;

      // console.debug("executeCostMstDetailApi() Config", config);
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.MST_COST_DETAIL, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // console.debug("executeCostMstDetailApi() Response", response);
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              const list = [];
              // 実車率単価マップ
              this.loadFactorMap = jsonData.resIdv.costDetails.loadfactor;
              // 稼働率単価マップ
              this.utilizationRateMap = jsonData.resIdv.costDetails.utilizationRate;
              jsonData.resIdv.costDetails.costDivList.forEach((row) => {
                list.push({
                  costDiv: row.costdiv,
                  costItemList: this.analysisCostItemList(row.costItemList, row.costdiv),
                });
              });
              // 原価区分リスト
              this.costDivList = list;
              // 実車率更新
              this.loadFactor = this.convertToInteger(this.loadFactorMap.unitPrice);
              // 稼働率更新
              this.utilizationRate = this.convertToInteger(this.utilizationRateMap.unitPrice);
              // 原価区分リストの原価区分から変動費・固定費それぞれの計算処理・設定を行う。
              this.costDivList.forEach((row) => {
                // 変動費の場合
                if (row.costDiv == "10") {
                  this.variableCostList = this.makeList(row.costItemList, row.costDiv);
                  this.calcVariableCost(this.variableCostList);
                } else if (row.costDiv == "20") {
                  // 固定費の場合
                  this.fixedCostList = this.makeList(row.costItemList, row.costDiv);
                  this.calcFixedCost(this.fixedCostList);
                }
              });
              console.debug(this.items);
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 標準原価詳細参照APIレスポンスの原価項目リスト解析処理
     */
    analysisCostItemList(item, costDiv) {
      // 返却値
      const costItemList = [];
      item.forEach((row) => {
        costItemList.push({
          costItemCd: row.costItemdCd,
          calcType: row.calcType,
          unitPriceList: this.analysisUnitPriceList(row.unitPriceList, costDiv),
        });
      });
      return costItemList;
    },

    /**
     * 標準原価詳細参照APIレスポンスの単価リスト解析処理
     */
    analysisUnitPriceList(item, costDiv) {
      // 返却値
      const unitPriceList = [];
      // 有効期限リスト
      const expireDateList = [];
      item.forEach((row) => {
        unitPriceList.push({
          costItemCd: row.costItemCd,
          costItemName: row.costItemName,
          costItemDetailCd: row.costItemDetailCd,
          costItemDetailName: row.costItemDetailName,
          unitPrice: this.convertToInteger(row.unitPrice),
          unitName: row.unitCdName,
          validFrom: row.validFrom,
          validTo: row.validTo,
          calcOrder: row.calcOrder,
          unitCd: row.unitCd,
          unitPriceSid: row.unitPriceSid,
        });
        expireDateList.push({
          costItemCd: row.costItemCd,
          costItemDetailCd: row.costItemDetailCd,
          unitPrice: this.convertToInteger(row.unitPrice),
          validFrom: row.validFrom,
          validTo: row.validTo,
          unitPriceSid: row.unitPriceSid,
          costDiv: costDiv,
          isAdd: false,
          deleteFlg: "0",
        });
      });
      this.expireDateList.push(...expireDateList);
      // 有効開始日付でソート
      this.expireDateList.sort((a, b) => {
        if (a.validFrom < b.validFrom) return -1;
        if (a.validFrom > b.validFrom) return 1;
        return 0;
      });
      return unitPriceList;
    },

    /**
     * 変動費・固定費リスト作成処理
     */
    makeList(item, costDiv) {
      const list = [];
      item.forEach((v) => {
        v.unitPriceList.forEach((item) => {
          let expireDateList = [];
          this.expireDateList.forEach((v) => {
            if (v.costItemDetailCd == item.costItemDetailCd && v.costItemCd == item.costItemCd) {
              expireDateList.push(v);
            }
          });

          list.push({
            costItemCd: v.costItemCd,
            costItemName: item.costItemName,
            costItemDetailCd: item.costItemDetailCd,
            costItemDetailName: item.costItemDetailName,
            unitPrice: item.unitPrice,
            unitName: item.unitName,
            unitPriceSid: item.unitPriceSid,
            validFrom: item.validFrom,
            validTo: item.validTo,
            calcOrder: item.calcOrder,
            unitCd: item.unitCd,
            calcType: v.calcType,
            costDiv: costDiv,
            expireDateList: expireDateList,
            deleteFlg: "0",
          });
        });
      });
      return list;
    },

    /**
     * 単価フォーマット変更
     */
    convertToInteger(val) {
      let unitPrice = val.replaceAll(".00", "");
      if (!isNaN(Number(unitPrice))) {
        if (Number(unitPrice) != 0) {
          return commonUtil.formatToCurrency(Number(unitPrice));
        } else {
          return "0";
        }
      } else {
        return "0";
      }
    },

    /**
     * 変動費の計算処理
     */
    calcVariableCost(item) {
      // 原価項目リスト
      const costItemList = item;
      // 原価コードでグルーピング
      const costGroupbyCostItemCd = Object.groupBy(costItemList, (v) => v.costItemCd);
      // 原価コードのキー取得
      const costItemCd = Object.keys(costGroupbyCostItemCd);
      this.variableCostDetails.splice(0);
      // キーを回しながら一致する単価リストを取得
      costItemCd.forEach((v) => {
        var includeSubTotalList = [];
        includeSubTotalList = this.calcSubTotal(costGroupbyCostItemCd[v]);

        this.variableCostDetails.push(...includeSubTotalList);
      });
      // 変動費内訳選択時表示用リストに追加
      if (this.toggleCostDiv == appConfig.COST_BREAKDOWN_DIV.VARIABLE_COST) {
        this.items.splice(0);
        this.items.push(...this.variableCostDetails);
      }

      this.calcActVariableCost(this.variableCostDetails);
    },

    /**
     * 固定費の計算処理
     */
    calcFixedCost(item) {
      // 原価項目リスト
      const costItemList = item;
      // 原価コードでグルーピング
      const costGroupbyCostItemCd = Object.groupBy(costItemList, (v) => v.costItemCd);
      // 原価コードのキー取得
      const costItemCd = Object.keys(costGroupbyCostItemCd);
      this.fixedCostDetails.splice(0);
      // キーを回しながら一致する単価リストを取得
      costItemCd.forEach((v) => {
        var includeSubTotalList = [];
        includeSubTotalList = this.calcSubTotal(costGroupbyCostItemCd[v]);

        this.fixedCostDetails.push(...includeSubTotalList);
      });
      // 固定費内訳選択時表示用リストに追加
      if (this.toggleCostDiv == appConfig.COST_BREAKDOWN_DIV.FIXED_COST) {
        this.items.splice(0);
        this.items.push(...this.fixedCostDetails);
      }

      this.calcActFixedCost(this.fixedCostDetails);
    },

    /**
     * 小計の計算処理と小計テーブル作成
     */
    calcSubTotal(item) {
      const currentDate = dateTimeHelper.convertJstDateTime(new Date()).setHours(0, 0, 0, 0);
      const groupedItems = Object.groupBy(item, (i) => i.costItemDetailCd);
      const validGroupedItems = [];

      for (const group of Object.values(groupedItems)) {
        const validItems = group.filter(
          (item) => item.calcOrder && this.isExpireDateRange(item.validFrom, item.validTo)
        );
        if (validItems.length === 0) {
          // 有効なアイテムがない場合は、最も近い有効期限のアイテムを追加
          const closest = group.reduce(
            (closest, item) => {
              const diff = Math.abs(new Date(item.validFrom) - currentDate);
              return diff < closest.diff ? { item, diff } : closest;
            },
            { item: null, diff: Infinity }
          ).item;
          validGroupedItems.push(closest);
        } else {
          validGroupedItems.push(...validItems);
        }
      }

      const subtotal = this.calculationSubTotal(validGroupedItems);

      return [
        ...validGroupedItems,
        {
          costItemType: "Subtotal",
          costItemCd: "",
          calcItemCd: validGroupedItems[0].costItemCd,
          costItemName: i18n.tc("label.lbl_subTotal"),
          cost: this.formatCost(subtotal),
        },
      ];
    },

    /**
     * 小計の計算処理
     */
    calculationSubTotal(items) {
      let calcSubTotal = 0;
      let firstCalcNum = "";
      let secondCalcNum = "";
      let thirdCalcNum = "";
      let arrLength = items.length;
      if (arrLength > 1) {
        // 計算順序があるのみフィルター
        const calcCostItem = items.filter((v) => {
          return v.calcOrder != "" && v.calcOrder != null && v.calcOrder != " ";
        });
        // 計算順序でソート
        calcCostItem.sort((a, b) => {
          if (a.calcOrder < b.calcOrder) return -1;
          if (a.calcOrder > b.calcOrder) return 1;
          return 0;
        });
        firstCalcNum = calcCostItem[0].unitPrice;
        secondCalcNum = calcCostItem[1].unitPrice;
        if (calcCostItem[0].calcType == "1") {
          calcSubTotal = this.calcDivision(firstCalcNum, secondCalcNum);
        } else if (calcCostItem[0].calcType == "2") {
          calcSubTotal = this.calcMultiple(firstCalcNum, secondCalcNum);
        } else if (calcCostItem[0].calcType == "3") {
          thirdCalcNum = calcCostItem[2].unitPrice;
          // 単位が%の場合
          if (calcCostItem[2].unitCd == "39") {
            thirdCalcNum = thirdCalcNum / 100;
          }
          calcSubTotal = this.calcDivision(firstCalcNum, secondCalcNum);
          calcSubTotal = this.calcMultiple(calcSubTotal, thirdCalcNum);
        }
      }
      return calcSubTotal;
    },

    /**
     * 徐算
     */
    calcDivision(firstCalcNum, secondCalcNum) {
      if (isNaN(firstCalcNum)) {
        var firstNum = firstCalcNum.replaceAll(",", "");
      } else {
        firstNum = firstCalcNum;
      }

      if (isNaN(secondCalcNum)) {
        var secondNum = secondCalcNum.replaceAll(",", "");
      } else {
        secondNum = secondCalcNum;
      }
      if (firstNum != "0" && secondCalcNum != "0") {
        return Math.ceil(Number(firstNum / secondNum) * 100) / 100;
      } else {
        return 0;
      }
    },

    /**
     * 乗算
     */
    calcMultiple(firstCalcNum, secondCalcNum) {
      if (isNaN(firstCalcNum)) {
        var firstNum = firstCalcNum.replaceAll(",", "");
      } else {
        firstNum = firstCalcNum;
      }

      if (isNaN(secondCalcNum)) {
        var secondNum = secondCalcNum.replaceAll(",", "");
      } else {
        secondNum = secondCalcNum;
      }
      return Math.ceil(Number(firstNum * secondNum) * 100) / 100;
    },

    /**
     * 実変動費と実変動費計の計算
     */
    calcActVariableCost(items) {
      // 実変動費
      let actVariableCost = 0;
      // 実変動費計
      let actVariableCostTotal = 0;
      // 実車率
      let loadFactorLate = Number(this.loadFactor) / 100;

      items.forEach((value) => {
        var subTotalCost = "0";
        if (value.cost) {
          subTotalCost = commonUtil.zen2han(value.cost).replaceAll(",", "").replaceAll("￥", "");
          actVariableCost = Number(actVariableCost) * 100;
          subTotalCost = Number(subTotalCost) * 100;
          actVariableCost = Math.ceil(Number(actVariableCost + subTotalCost)) / 100;
        }
      });
      // 計算結果が最大費用を超えた場合
      if (this.isMaxCost(actVariableCost)) {
        actVariableCost = this.maxCost;
      }
      // 実変動費の設定
      this.actVariableCost = this.formatCost(actVariableCost);

      // 実変動費計の計算
      actVariableCostTotal = this.calcDivision(actVariableCost, loadFactorLate);
      // 計算結果が最大費用を超えた場合
      if (this.isMaxCost(actVariableCostTotal)) {
        actVariableCostTotal = this.maxCost;
      }
      // 実変動費計の設定
      this.actVariableCostTotal = this.formatCost(actVariableCostTotal);
    },

    /**
     * 実固定費と実固定費計の計算
     */
    calcActFixedCost(items) {
      // 実固定費
      let actFixedCost = 0;
      // 実固定費計
      let actFixedCostTotal = 0;
      // 稼働率
      let utilizationRate = Number(this.utilizationRate) / 100;
      // 1ヶ月稼働時間
      let uptimeOnemonth = items.find(
        (v) => v.costItemCd == "C1006" && v.costItemDetailCd == "CI1013"
      );

      items.forEach((value) => {
        var subTotalCost = "0";
        if (value.cost) {
          subTotalCost = commonUtil.zen2han(value.cost).replaceAll(",", "").replaceAll("￥", "");
          actFixedCost = Number(actFixedCost) * 100;
          subTotalCost = Number(subTotalCost) * 100;
          actFixedCost = Math.ceil(Number(actFixedCost + subTotalCost)) / 100;
        }
      });
      if ((uptimeOnemonth ?? false) && uptimeOnemonth != "") {
        let uptimeUnitPrice = Number(uptimeOnemonth.unitPrice.replaceAll(",", ""));
        // 小計の合計と1ヶ月稼働時間で除算
        actFixedCostTotal = this.calcDivision(actFixedCost, uptimeUnitPrice);
      }
      // 計算結果が最大費用を超えた場合
      if (this.isMaxCost(actFixedCost)) {
        actFixedCost = this.maxCost;
      }
      // 実固定費の設定
      this.actFixedCost = this.formatCost(actFixedCost);

      // 実固定費計の計算
      actFixedCostTotal = this.calcDivision(actFixedCostTotal, utilizationRate);
      // 計算結果が最大費用を超えた場合
      if (this.isMaxCost(actFixedCostTotal)) {
        actFixedCostTotal = this.maxCost;
      }
      // 実固定費計の設定
      this.actFixedCostTotal = this.formatCost(actFixedCostTotal);
    },

    /**
     * 円マーク、カンマを付与
     */
    formatCost(num) {
      return num.toLocaleString("ja-JP", {
        style: "currency",
        currency: "JPY",
        minimumFractionDigits: 2,
      });
    },

    /**
     * 費用の最大確認
     */
    isMaxCost(calcResult) {
      if (calcResult > this.maxCost) {
        return true;
      }
      return false;
    },
    /**
     * 実車率変更時処理
     */
    changeLoadFactor(value) {
      if (this.isValCorrect(value)) {
        this.calcActVariableCost(this.variableCostDetails);
      }
    },

    /*
     * 稼働率変更時処理
     */
    changeUtilizationRate(value) {
      if (this.isValCorrect(value)) {
        this.calcActFixedCost(this.fixedCostDetails);
      }
    },
    /**
     * 単価変更時の処理
     */
    changeUnitPrice(item, index) {
      // 有効期限ダイアログ単価変更行インデックス
      let expireDateChangeIndex = 0;
      // 有効期限範囲チェック判定フラグ
      let isAvailableDate = false;
      if (
        this.$refs.editedList.validate() &&
        this.isCorrectLoadFactorAndUtilizationRate(this.loadFactor, this.utilizationRate)
      ) {
        let price = item.unitPrice.replaceAll(",", "");
        // 単価
        let unitPrice = Number(price) != 0 ? commonUtil.formatToCurrency(Number(price)) : "0";
        // 表示用リストに変更後の単価を設定
        this.items[index].unitPrice = unitPrice;
        // 有効期限ダイアログの単価を変更
        this.items[index].expireDateList.forEach((v) => {
          // 有効期限範囲内の場合、その行の単価を変更
          if (this.isExpireDateRange(v.validFrom, v.validTo)) {
            isAvailableDate = true;
            v.unitPrice = unitPrice;
          }
        });
        // 有効期限範囲内のアイテムが存在しない場合、最も近い有効期限のインデックスの単価を変更
        if (!isAvailableDate) {
          expireDateChangeIndex = this.getClosedExpireDateIndex(this.items[index].expireDateList);
          this.items[index].expireDateList[expireDateChangeIndex].unitPrice = unitPrice;
        }
        this.costCalAgain(this.items);
      }
    },
    /**
     * 費用の再計算処理
     */
    costCalAgain(items) {
      // 小計再計算のため表示用リストから小計のレコードを除去
      const unitUpdateList = items.filter((v) => {
        return !v.costItemType;
      });

      // 変動費選択時
      if (this.toggleCostDiv == appConfig.COST_BREAKDOWN_DIV.VARIABLE_COST) {
        this.calcVariableCost(unitUpdateList);
        // 固定費選択時
      } else if (this.toggleCostDiv == appConfig.COST_BREAKDOWN_DIV.FIXED_COST) {
        this.calcFixedCost(unitUpdateList);
      }
    },
    /**
     * 有効期限範囲チェック
     */
    isExpireDateRange(validFrom, validTo) {
      // 現在日付
      const currentDate = dateTimeHelper.convertJstDateTime(new Date()).setHours(0, 0, 0, 0);
      if (new Date(validFrom) <= currentDate && new Date(validTo) >= currentDate) {
        return true;
      }
      return false;
    },
    /**
     * 最も近い有効期限アイテムのインデックスを取得
     */
    getClosedExpireDateIndex(items) {
      // 現在日付
      const currentDate = dateTimeHelper.convertJstDateTime(new Date()).setHours(0, 0, 0, 0);
      const closestIndex = items.reduce(
        (closest, item, index) => {
          const diff = Math.abs(new Date(item.validFrom) - currentDate);
          return diff < closest.diff ? { index, diff } : closest;
        },
        { index: 0, diff: Infinity }
      ).index;
      return closestIndex;
    },
    /**
     * 入力値・計算値0チェック
     */
    isNumberZero(val) {
      let num = Number(val);
      if (num == 0) {
        return true;
      }
      return false;
    },
    /**
     * 有効期限モーダル設定時処理
     */
    parentMethod: function (deleteList, isConfig, target) {
      this.deleteList = deleteList;
      if (isConfig) {
        this.updateDialogMessage = false;
        // 費用再計算
        this.costCalAgain(target);
      }
    },

    /**
     * コピーボタン押下時処理
     */
    copyTargetVehicleData() {
      // 車両プルダウン選択時のみ、処理する。
      if (this.isNullorBlank(this.copyTargetVehicle)) {
        const arg = `${
          this.copyTargetVehicleList.find((v) => v.value == this.copyTargetVehicle).text
        }`;
        this.confirmDialog.message = messsageUtil.getMessage("P-CLA-002_001_C", [arg]);
        this.confirmDialog.isOpen = true;
        this.confirmDialog.screenFlag = true;
        this.confirmDialog.changeFlag = false;
        this.confirmDialog.okAction = this.copyVehicleData;
      } else {
        return;
      }
    },

    /**
     * 確認ダイアログOKボタン押下処理
     */
    copyVehicleData() {
      this.isCopyItems = true;
      this.executeCostMstDetailApi(this.copyTargetVehicle);
    },

    /**
     * 登録ボタン押下処理
     */
    displayRegistrationConfirmationDialog() {
      this.confirmDialog.message = messsageUtil.getMessage("P-RCV-001_003_C");
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.registerInfo;
    },

    /**
     * 登録実行処理
     */
    registerInfo() {
      if (
        this.$refs.editedList.validate() &&
        this.isCorrectLoadFactorAndUtilizationRate(this.loadFactor, this.utilizationRate)
      ) {
        // 登録対象リスト
        let registTarget = [];
        let deleteTaget = [];
        let itemsList = [];
        itemsList.push(...this.variableCostDetails, ...this.fixedCostDetails);
        // 小計を除去したリスト作成
        let target = itemsList.filter((v) => {
          return !v.costItemType;
        });
        registTarget.push(...target);

        // 削除対象リストがある場合、登録対象へ挿入
        deleteTaget = this.makeDeleteTarget(this.variableCostList);
        if (deleteTaget.length > 0) {
          registTarget.push(...deleteTaget);
        }
        deleteTaget = this.makeDeleteTarget(this.fixedCostList);
        if (deleteTaget.length > 0) {
          registTarget.push(...deleteTaget);
        }
        // 有効期限を追加されたレコードがある場合、登録対象へ挿入
        target.forEach((v) => {
          v.expireDateList.forEach((val) => {
            if (val.isAdd) {
              registTarget.push(val);
            }
          });
        });
        // 有効期限範囲外で変更のあったレコードがある場合、登録対象へ挿入
        target.forEach((v) => {
          if (v.expireDateList.length > 1) {
            v.expireDateList.forEach((val) => {
              if (!val.isAdd && !this.isExpireDateRange(val.validFrom, val.validTo)) {
                let expireDate = this.expireDateList.find(
                  (v) => v.unitPriceSid == val.unitPriceSid
                );
                let tmpExpireDate = JSON.stringify(expireDate);
                let updateTarget = JSON.stringify(val);
                if (updateTarget != tmpExpireDate) {
                  registTarget.push(val);
                }
              }
            });
          }
        });
        if (registTarget.length > 0) {
          this.executeCostUnitInsUpd(registTarget);
        }
      }
    },
    /**
     * 標準原価マスタ詳細登録・更新API実行処理
     */
    executeCostUnitInsUpd(target) {
      // ローディング表示ON
      this.loadingCounter = 1;
      // 単価リスト
      let unitPriceList = [];
      // 登録・更新マップ
      let costUnitUpdate = {};
      let variableCostCalc = String(this.actVariableCost);
      let fixedCostCalc = String(this.actFixedCost);
      let isSuccess = false;
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_CLA_002;
      // 車両SID
      costUnitUpdate.vehicleSid = this.vehicleSid;
      // 変動費計
      costUnitUpdate.variableCostCalc = variableCostCalc.replaceAll("￥", "").replaceAll(",", "");
      // 実車率
      costUnitUpdate.loadFactor = String(this.loadFactor);
      // 固定費計
      costUnitUpdate.fixedCostCalc = fixedCostCalc.replaceAll("￥", "").replaceAll(",", "");
      // 稼働率
      costUnitUpdate.utilizationRate = String(this.utilizationRate);
      target.forEach((value) => {
        let unitPrice = value.unitPrice.replaceAll(",", "");
        unitPriceList.push({
          unitPriceSid: this.isCopyItems ? "" : value.unitPriceSid,
          validFrom: value.validFrom,
          validTo: value.validTo,
          costDiv: value.costDiv,
          costItemCd: value.costItemCd,
          costItemDetailCd: value.costItemDetailCd,
          unitPrice: unitPrice,
          deleteFlg: value.deleteFlg,
        });
      });
      // 原価リスト
      costUnitUpdate.costUnitList = unitPriceList;
      body.reqIdv.costUnitUpdate = costUnitUpdate;
      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.MST_COST_UNIT_UPDATE, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("planRegistion() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = i18n.tc("label.lbl_result");
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = false;
              this.infoDialog.outsideClickNotCloseFlg = true;
              this.isCopyItems = false;
              isSuccess = true;
              resolve();
            } else {
              // エラーメッセージをpopupのmessageに格納
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              this.infoDialog.homePageFlag = false;
              this.infoDialog.mstCostListFlg = false;
              this.infoDialog.mstCostDetailFlg = false;
              this.infoDialog.outsideClickNotCloseFlg = false;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex.message;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            this.infoDialog.homePageFlag = false;
            this.infoDialog.mstCostListFlg = false;
            this.infoDialog.mstCostDetailFlg = false;
            this.infoDialog.outsideClickNotCloseFlg = false;
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            if (isSuccess) {
              this.executeCostMstDetailApi(this.vehicleSid);
              this.endEvent();
            }
          });
      });
    },

    /**
     * 登録完了ダイアログOKボタン押下処理
     */
    afterRegistOk() {
      this.executeCostMstDetailApi(this.vehicleSid);
    },

    // イベント終了ダイアログ表示
    endEvent() {
      this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
      this.infoDialog.title = i18n.tc("label.lbl_result");
      this.infoDialog.isOpen = true;
      this.infoDialog.screenFlag = true;
      // 画面遷移ボタン
      this.infoDialog.homePageFlag = true;
      this.infoDialog.mstCostListFlg = true;
      this.infoDialog.mstCostDetailFlg = true;
    },

    /**
     * 削除対象リスト作成処理
     *
     */
    makeDeleteTarget(items) {
      // 返却値
      let deleteTaget = [];
      // 削除リストから単価SIDを抽出し、リスト化
      if (this.deleteList.length > 0) {
        let unitPriceSidList = this.deleteList.map((v) => v.unitPriceSid);
        unitPriceSidList.forEach((v) => {
          items.forEach((val) => {
            if (v == val.unitPriceSid) {
              val.deleteFlg = "1";
              deleteTaget.push(val);
            }
          });
        });
      }
      return deleteTaget;
    },
    // 存在チェック
    isNullorBlank(val) {
      if (val != null || val != "") {
        return true;
      } else {
        return false;
      }
    },

    /**
     * 戻るボタン処理
     */
    backPage() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    /**
     * 標準原価マスタ一覧画面へ渡す値を設定
     */
    updateOk() {
      this.updateDialogMessage = false;
      this.$router.push({
        name: appConfig.SCREEN_ID.P_CLA_001,
        params: {
          vehicleSid: this.$route.params.vehicleSid,
          list_vehicleSid: this.$route.params.list_vehicleSid,
        },
      });
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },
    /**
     * 実車率と稼働率のバリデーションチェック
     */
    isCorrectLoadFactorAndUtilizationRate(loadFactor, utilizationRate) {
      // 数値かどうかの確認
      if (this.isValCorrect(loadFactor) && this.isValCorrect(utilizationRate)) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 数値のチェック
     */
    isValCorrect(value) {
      if ((value ?? false) && value != "") {
        let val = commonUtil.zen2han(value).replaceAll(",", "");
        // 数値かどうかの確認
        if (isNaN(Number(val))) {
          return false;
        }
        // 全体の桁数が5桁であるか
        var check = /^([0-9]{1,3}|0)([.][0-9]{1,2})?$/g.test(val);
        if (check != true) {
          return false;
        }

        // 範囲チェック
        if (val < 0 || val > 100) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
.cost-div-name {
  font-size: large;
  font-weight: bold;
}
.detail-table {
  width: 100%;
  border-collapse: collapse;
  border-color: black;
  table-layout: fixed;
}
.detail-table th {
  background-color: #effad9;
  font-size: large;
}
.detail-table th p {
  margin: 0;
}
.detail-th-sub {
  font-size: small;
}
.detail-table td {
  font-size: x-large;
  padding: 8px;
  text-align: center;
}
.detail-tf input {
  text-align: right;
}
.cost-selected {
  background-color: #fff2cc;
}
.cost-tr-subtotal {
  background-color: #deebf7;
}
.cost-tr-total {
  background-color: #fbe5d6;
}
</style>
<style lang="scss" scoped>
#costData {
  &.v-data-table--fixed-header ::v-deep {
    th {
      font-size: large !important;
      color: black;
      background: #effad9;
      height: 2.5rem !important;

      &.asta::after {
        content: "*" !important;
        color: red;
      }

      &:not(:nth-child(0)) {
        &::before {
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    td {
      font-size: large;
    }
  }
}
</style>
