<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_costMasterList" />
      <!-- 標準原価マスタ一覧 -->
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-row-exeBtn">
              <!-- 車両 -->
              <div class="search-autocomplete first-search-item" style="float: left; width: 450px">
                <v-autocomplete
                  dense
                  v-model="vehiclesSelected"
                  :items="vehicleList"
                  :label="$t('label.lbl_vehicle')"
                  class="txt-single"
                  @input="inputVehicle"
                ></v-autocomplete>
              </div>

              <div class="search-autocomplete search-item-info" style="float: left; width: 800px">
                <v-row>
                  <v-col cols="2" style="text-align: left">
                    <div style="font-size: 15px">&emsp;{{ modelLbl }}</div>
                    <div style="font-size: 15px">&emsp;{{ lwhLbl }}</div>
                  </v-col>
                  <v-col cols="5" style="text-align: left">
                    <div style="font-size: 15px">{{ modelData }}</div>
                    <div style="font-size: 15px">{{ lwhData }}</div>
                  </v-col>
                  <v-col cols="2" style="text-align: left">
                    <div style="font-size: 15px">&emsp;{{ vehicleWeightLbl }}</div>
                    <div style="font-size: 15px">&emsp;{{ maximumPayloadLbl }}</div>
                  </v-col>
                  <v-col cols="3" style="text-align: left">
                    <div style="font-size: 15px">{{ vehicleWeightData }}</div>
                    <div style="font-size: 15px">{{ maximumPayloadData }}</div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン-->
                <v-btn color="primary" class="api-btn" @click="search">
                  {{ $t("btn.btn_search") }}
                </v-btn>
                <!--画面クリアボタン-->
                <!--<v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>-->
              </div>
            </div>
          </v-row>
        </v-container>

        <!-- データテーブル -->
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="costInfoList"
            disable-filtering
            :hide-default-footer="true"
            height="511px"
          >
            <!-- ヘッダ項目 -->
            <!-- 車両 -->
            <template v-slot:[`header.vehicle`]="{ header }">
              <v-row>
                <v-col>
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px">&nbsp;</div>
                </v-col>
              </v-row>
            </template>
            <!-- 変動費 -->
            <template v-slot:[`header.variableCosts`]="{ header }">
              <v-row>
                <v-col>
                  <div style="width: 93%">{{ $t("label.lbl_variableCosts") }}</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px; width: 90%">{{ $t("label.lbl_per1kg") }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 実車率 -->
            <template v-slot:[`header.actualVehicleRate`]="{ header }">
              <v-row>
                <v-col>
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px">&nbsp;</div>
                </v-col>
              </v-row>
            </template>
            <!-- 実変動費計 -->
            <template v-slot:[`header.variableActualCostTotal`]="{ header }">
              <v-row>
                <v-col>
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px; width: 85%">{{ $t("label.lbl_per1kg") }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 固定費 -->
            <template v-slot:[`header.fixedCosts`]="{ header }">
              <v-row>
                <v-col>
                  <div style="width: 95%">{{ $t("label.lbl_fixedCosts") }}&nbsp;</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px; width: 92%">{{ $t("label.lbl_per1kg") }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 稼働率 -->
            <template v-slot:[`header.occupancyRate`]="{ header }">
              <v-row>
                <v-col>
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px">&nbsp;</div>
                </v-col>
              </v-row>
            </template>
            <!-- 実固定費計 -->
            <template v-slot:[`header.fixedActualCostTotal`]="{ header }">
              <v-row>
                <v-col>
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px; width: 83%">{{ $t("label.lbl_per1kg") }}</div>
                </v-col>
              </v-row>
            </template>
            <!-- 詳細 -->
            <template v-slot:[`header.detail`]="{ header }">
              <v-row>
                <v-col>
                  <div>&nbsp;</div>
                  <div>{{ header.text }}</div>
                  <div style="font-size: 13px">&nbsp;</div>
                </v-col>
              </v-row>
            </template>
            <!-- 明細項目 -->
            <!-- 車両 -->
            <template v-slot:[`item.vehicle`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.lbl_vehicle"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_vehicle')"
                >
                </v-text-field>
              </div>
              <div v-else class="align-left">{{ item.vehicle }}</div>
            </template>
            <!-- 変動費 -->
            <template v-slot:[`item.variableCosts`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.variableCosts"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_variableCosts')"
                >
                </v-text-field>
              </div>
              <div v-else class="align-cost-right">{{ item.variableCosts }}</div>
            </template>
            <!-- 実車率 -->
            <template v-slot:[`item.actualVehicleRate`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.actualVehicleRate"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_actualVehicleRate')"
                >
                </v-text-field>
              </div>
              <div v-else class="align-rate-right">{{ item.actualVehicleRate }}</div>
            </template>
            <!-- 実変動費計 -->
            <template v-slot:[`item.variableActualCostTotal`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.variableActualCostTotal"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_actualVariableCosts')"
                >
                </v-text-field>
              </div>
              <div v-else class="align-cost-right">
                {{ item.variableActualCostTotal }}
              </div>
            </template>
            <!-- 固定費 -->
            <template v-slot:[`item.fixedCosts`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.fixedCosts"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_fixedCosts')"
                >
                </v-text-field>
              </div>
              <div v-else class="align-cost-right">{{ item.fixedCosts }}</div>
            </template>
            <!-- 稼働率 -->
            <template v-slot:[`item.occupancyRate`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.occupancyRate"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_occupancyRate')"
                >
                </v-text-field>
              </div>
              <div v-else class="align-rate-right">{{ item.occupancyRate }}</div>
            </template>
            <!-- 実固定費計 -->
            <template v-slot:[`item.fixedActualCostTotal`]="{ item }">
              <div v-if="item.isNewCreation">
                <v-text-field
                  outlined
                  dense
                  v-model="item.fixedActualCostTotal"
                  class="text-box"
                  clear-icon="mdi-close-circle"
                  clearable
                  :label="$t('label.lbl_actualVariableCosts')"
                >
                </v-text-field>
              </div>
              <div v-else class="align-cost-right">{{ item.fixedActualCostTotal }}</div>
            </template>
            <template v-slot:[`item.detail`]="{ item }">
              <v-btn small @click="jumpTo(item)" text>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>

        <v-navigation-drawer absolute temporary v-model="openMenu">
          <sideMenu></sideMenu>
        </v-navigation-drawer>
        <SimpleDialog
          :isShow.sync="infoDialog.isOpen"
          :title="infoDialog.title"
          :message="infoDialog.message"
        />
        <ConfirmDialog
          :isShow.sync="confirmDialog.isOpen"
          :message="confirmDialog.message"
          :screenFlag="confirmDialog.screenFlag"
          :okAction="confirmDialog.okAction"
          :redMessage="confirmDialog.redMessage"
          :changeFlag="confirmDialog.changeFlag"
        />
      </v-form>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { i18n } from "../../lang/lang.js";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: appConfig.MENU_ID.P_CLA_COST_LIST,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    // 初期データ
    defaultData: [],
    // ローディング画面表示フラグ
    loadingCounter: 0,
    // メニュー
    openMenu: null,
    // 車両
    vehiclesSelected: "",
    // 形式
    modelLbl: "",
    modelData: "",
    // 長さ/幅/高さ
    lwhLbl: "",
    lwhData: "",
    // 車両重量
    vehicleWeightLbl: "",
    vehicleWeightData: "",
    // 最大積載重量
    maximumPayloadLbl: "",
    maximumPayloadData: "",
    // 計算後実変動費計
    calcVariableActualCostTotal: 0,
    calcFixedActualCostTotal: 0,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    // 確認メッセージ
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    // ヘッダ
    headerItems: [
      // 車両
      {
        text: i18n.tc("label.lbl_vehicle"),
        value: "vehicle",
        width: "10%",
        align: "left",
        sortable: false,
      },
      // 変動費・変動費計
      {
        text: i18n.tc("label.lbl_totalVariableCosts"),
        value: "variableCosts",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 実車率
      {
        text: i18n.tc("label.lbl_actualVehicleRate"),
        value: "actualVehicleRate",
        width: "3%",
        align: "center",
        sortable: false,
      },
      // 実変動費計
      {
        text: i18n.tc("label.lbl_actualVariableCosts"),
        value: "variableActualCostTotal",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 固定費・固定費計
      {
        text: i18n.tc("label.lbl_totalFixedCosts"),
        value: "fixedCosts",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 稼働率
      {
        text: i18n.tc("label.lbl_occupancyRate"),
        value: "occupancyRate",
        width: "3%",
        align: "center",
        sortable: false,
      },
      // 実固定費計
      {
        text: i18n.tc("label.lbl_actualFixedCosts"),
        value: "fixedActualCostTotal",
        width: "5%",
        align: "center",
        sortable: false,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_detail"),
        value: "detail",
        width: "3%",
        align: "center",
        sortable: false,
      },
    ],
    detailDialogData: {},
    costInfoList: [],
    vehicleList: [],
  }),
  methods: {
    // 検索ボタン
    search(isInit) {
      if (this.$refs.form.validate() || isInit) {
        this.getCostInfoList();
      }
    },

    // 車両API接続
    getvehicleList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 車両
      const gVehicleList = getParameter.getVehicleMst(this.vehiclesSelected);
      Promise.all([gVehicleList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.vehicleList = result[0];
          // 先頭に空の配列を追加
          this.vehicleList.unshift({
            text: "",
            value: "",
            name: "",
            model: "",
            weight: "",
            length: "",
            width: "",
            height: "",
            payload: "",
          });
          this.inputVehicle();
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      this.vehiclesSelected = null;
      this.getvehicleList();

      // 戻る遷移の場合
      // 車両リスト
      if (
        this.$route.params.list_vehicleSid ||
        ((this.$route.params.list_vehicleSid == null || this.$route.params.list_vehicleSid == "") &&
          this.$route.params.vehicleSid != null)
      ) {
        this.vehiclesSelected = this.$route.params.list_vehicleSid;
        // 一覧を表示
        this.getCostInfoList();
      }
    },

    // 詳細画面へ
    jumpTo(item) {
      if (this.$refs.form.validate()) {
        this.$router.push({
          name: appConfig.SCREEN_ID.P_CLA_002,
          params: {
            list_vehicleSid: this.vehiclesSelected,
            vehicleSid: item.vehicleSid,
          },
        });
      }
    },

    /**
     *  車両変更時処理(車両情報リストボックス)
     */
    inputVehicle() {
      const unit_kg = "kg";
      const unit_cm = "cm";
      const lbl_slash = "/";
      const lbl_colon = "：";

      if (this.vehiclesSelected == "" || this.vehiclesSelected == null) {
        this.modelLbl = "";
        this.lwhLbl = "";
        this.vehicleWeightLbl = "";
        this.maximumPayloadLbl = "";
        this.modelData = "";
        this.lwhData = "";
        this.vehicleWeightData = "";
        this.maximumPayloadData = "";
      } else {
        this.modelLbl = i18n.tc("label.lbl_format");
        this.lwhLbl = i18n.tc("label.lbl_lwh");
        this.vehicleWeightLbl = i18n.tc("label.lbl_vehicleWeight");
        this.maximumPayloadLbl = i18n.tc("label.lbl_maximumPayload");
        const fill = this.vehicleList.filter((person) => person.value === this.vehiclesSelected);
        this.modelData = lbl_colon + fill[0].model;
        this.lwhData =
          lbl_colon +
          fill[0].length +
          unit_cm +
          lbl_slash +
          fill[0].width +
          unit_cm +
          lbl_slash +
          fill[0].height +
          unit_cm;
        this.vehicleWeightData = lbl_colon + fill[0].weight + unit_kg;
        this.maximumPayloadData = lbl_colon + fill[0].payload + unit_kg;
      }
    },

    // 標準原価マスタ一覧参照API接続
    getCostInfoList() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 配列のクリア
      this.costInfoList = [];
      // 標準原価マスタ一覧
      const gCostInfoList = getParameter.getCostList(this.vehiclesSelected);
      Promise.all([gCostInfoList])
        .then((result) => {
          // 画面の明細を設定します。
          result[0].forEach((row) => {
            // 実変動費計を算出(小数点第3位切上げ)
            this.calcVariableActualCostTotal = this.calcDivision(
              Number(row.variableCosts) * 100,
              row.actualVehicleRate
            );
            // 実固定費計を算出(小数点第3位切上げ)
            this.calcFixedActualCostTotal = this.calcDivision(
              Number(row.fixedCosts) * 100,
              row.occupancyRate
            );
            // 実固定費計に1ヶ月稼働時間を適用
            this.calcFixedActualCostTotal = this.calcDivision(
              this.calcFixedActualCostTotal,
              row.uptimeOnemonth
            );
            this.costInfoList.push({
              vehicleSid: row.vehicleSid,
              vehicle: row.vehicle,
              variableCosts: this.formatCost(row.variableCosts),
              actualVehicleRate: this.formatRate(row.actualVehicleRate),
              variableActualCostTotal: this.formatCost(this.calcVariableActualCostTotal),
              fixedCosts: this.formatCost(row.fixedCosts),
              occupancyRate: this.formatRate(row.occupancyRate),
              fixedActualCostTotal: this.formatCost(this.calcFixedActualCostTotal),
            });
          });
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 徐算
     */
    calcDivision(firstCalcNum, secondCalcNum) {
      if (isNaN(firstCalcNum)) {
        var firstNum = firstCalcNum.replaceAll(",", "");
      } else {
        firstNum = firstCalcNum;
      }

      if (isNaN(secondCalcNum)) {
        var secondNum = secondCalcNum.replaceAll(",", "");
      } else {
        secondNum = secondCalcNum;
      }
      if (firstNum != "0" && secondCalcNum != "0") {
        return Math.ceil(Number(firstNum / secondNum) * 100) / 100;
      } else {
        return 0;
      }
    },

    /**
     * 円マーク、カンマを付与
     */
    formatCost(num) {
      return Number(num).toLocaleString("ja-JP", {
        style: "currency",
        currency: "JPY",
        minimumFractionDigits: 2,
      });
    },

    /**
     * パーセントを付与
     */
    formatRate(num) {
      return Number(num / 100).toLocaleString("ja-JP", {
        style: "percent",
        maximumFractionDigits: 2,
      });
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },

    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // クリアフラグ
      this.clearFlg = true;
      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}

.from_to {
  font-weight: bold;
  // padding-top: 8px;
  // margin-left: 0;
  // margin-right: 0;
}

.toatlNum {
  margin-left: 10px;
}

.v-data-table {
  white-space: pre-line;
}

.align-cost-right {
  text-align: right;
  width: "5%";
}

.align-rate-right {
  text-align: right;
  width: "3%";
}
.align-left {
  text-align: left;
  width: "5%";
}
</style>
