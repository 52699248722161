<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <Loading />
      <NavBar tittle="label.lbl_tansportOrderList" />
      <v-form ref="form" lazy-validation>
        <!-- 検索項目-->
        <v-container fluid>
          <!-- 1行目-->
          <v-row class="search-row">
            <!-- 取引先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="suppliersSelected"
                :items="supplierList"
                :label="$t('label.lbl_supplier')"
                :hint="setSrhSupplier()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :search-input.sync="supplierInput"
                @change="changeSupplier"
                :rules="[rules.isValue2Required(inquiryNumber, suppliersSelected)]"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 発注日(From)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="orderDateFrom"
                :label="$t('label.lbl_orderDateFrom')"
                @change="changeDateFrom($event, 'order')"
                :rules="[
                  rules.inputRequiredCreate(orderDateFrom, orderDateTo),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="orderDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="orderDateFromCal"
                  @input="orderDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevFromDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextFromDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 発注日(To) -->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="orderDateTo"
                :label="$t('label.lbl_orderDateTo')"
                @change="changeDateTo($event, 'order')"
                :rules="[
                  rules.inputRequiredCreate(orderDateTo, orderDateFrom),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="alertMessage"
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="orderDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="orderDateToCal"
                  @input="orderDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevToDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextToDate('order')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 輸送担当営業所 -->
            <div class="search-autocomplete">
              <v-autocomplete
                dense
                v-model="officeSelected"
                :items="officeList"
                :label="$t('label.lbl_transportOffice')"
                :hint="setSrhOffice()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                @change="changeOffice"
                :search-input.sync="getOfficeSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
                <!-- * -->
              </v-autocomplete>
            </div>
            <!-- <span class="item-spacer">&nbsp;</span> -->
            <!-- 配車計画状況-->
            <!-- <div style="float: left"> -->
            <!-- <span class="text-label">{{ $t("label.lbl_pickupStatusDiv") }}</span> -->
            <!-- 空白 -->
            <!-- <span class="item-spacer">&nbsp;</span> -->
            <!-- 未配車/配車 -->
            <!-- <v-btn-toggle
                mandatory
                v-model="pickupStatusDiv"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in pickupDispatchStatusDivList"
                  :key="item.value"
                  :value="item.value"
                  small
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle> -->
            <!-- 空白 -->
            <!-- <span class="item-spacer">&nbsp;</span>
              <span class="text-label">{{ $t("label.lbl_deliveryStatusDiv") }}</span>
              <span class="item-spacer">&nbsp;</span> -->
            <!-- 未配車/配車 -->
            <!-- <v-btn-toggle
                mandatory
                v-model="deliveryStatusDiv"
                color="blue darken-2"
                id="btn-toggle-btn-padding"
              >
                <v-btn
                  class="paid-btn"
                  v-for="item in deliveryDispatchStatusDivList"
                  :key="item.value"
                  :value="item.value"
                  small
                  style="width: 100px"
                >
                  {{ item.text }}</v-btn
                >
              </v-btn-toggle> -->
            <!-- </div> -->
          </v-row>

          <!-- 2段目 -->
          <v-row class="search-row" style="margin-top: 20px">
            <!-- 集荷元-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="pickupPointSelected"
                :items="pickupPointList"
                :label="$t('label.lbl_pickupPoint')"
                :hint="setSrhPickup()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                @change="changePickup"
                :search-input.sync="getPickupSelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>
            <!-- 集荷予定日(From)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="pickupScheduleDateFrom"
                :label="$t('label.lbl_pickupScheduleDateFrom')"
                @change="changeDateFrom($event, 'pickup')"
                :rules="[
                  rules.inputRequiredCreate(pickupScheduleDateFrom, pickupScheduleDateTo),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="pickupScheduleDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="pickupScheduleDateFromCal"
                  @input="pickupScheduleDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevFromDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextFromDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 集荷予定日(To)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="pickupScheduleDateTo"
                :label="$t('label.lbl_pickupScheduleDateTo')"
                @change="changeDateTo($event, 'pickup')"
                :rules="[
                  rules.inputRequiredCreate(pickupScheduleDateTo, pickupScheduleDateFrom),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="pickupAlertMessage"
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="pickupScheduleDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="pickupScheduleDateToCal"
                  @input="pickupScheduleDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevToDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextToDate('pickup')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <!-- 配達先-->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="deliveryPointSelected"
                :items="deliveryPointList"
                :label="$t('label.lbl_deliveryPoint')"
                :hint="setSrhDelivery()"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                @change="changeDelivery"
                :search-input.sync="getDeliverySelected"
              >
                <!-- アイテム一覧の表示 -->
                <template slot="item" slot-scope="data">
                  <span class="test">
                    {{ data.item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 配達予定日(From)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="deliveryScheduleDateFrom"
                :label="$t('label.lbl_deliveryScheduleDateFrom')"
                @change="changeDateFrom($event, 'delivery')"
                :rules="[
                  rules.inputRequiredCreate(deliveryScheduleDateFrom, deliveryScheduleDateTo),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
              ></v-text-field>
            </div>

            <div class="calender-btn-area">
              <v-menu
                v-model="deliveryScheduleDateFromMenu"
                :close-on-content-click="false"
                :nudge-right="-100"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryScheduleDateFromCal"
                  @input="deliveryScheduleDateFromMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevFromDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextFromDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>

            <div class="from_to">～</div>
            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 配達予定日(To)-->
            <div class="date-style">
              <v-text-field
                outlined
                dense
                v-model="deliveryScheduleDateTo"
                :label="$t('label.lbl_deliveryScheduleDateTo')"
                @change="changeDateTo($event, 'delivery')"
                :rules="[
                  rules.inputRequiredCreate(deliveryScheduleDateTo, deliveryScheduleDateFrom),
                  rules.yyyymmdd_create,
                ]"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :error-messages="deliveryAlertMessage"
              ></v-text-field>
            </div>
            <div class="calender-btn-area">
              <v-menu
                v-model="deliveryScheduleDateToMenu"
                :close-on-content-click="false"
                :nudge-right="-200"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="date-calendar"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text x-small>
                    <v-icon class="icon-calendar">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  v-model="deliveryScheduleDateToCal"
                  @input="deliveryScheduleDateToMenu = false"
                ></v-date-picker>
              </v-menu>
              <v-btn
                id="btn-yesterday"
                class="day-ctrl-btn"
                @click="prevToDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                id="btn-tomorow"
                class="day-ctrl-btn"
                @click="nextToDate('delivery')"
                text
                x-small
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
          <!-- 3段目 -->
          <v-row class="search-row">
            <!-- お客様問合せ番号 -->
            <div class="add-textbox-remark first-search-item">
              <v-text-field
                outlined
                dense
                v-model="inquiryNumber"
                :label="$t('label.lbl_inquiryNumber')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                :rules="[
                  rules.onlyOneInputRequired(
                    inquiryNumber,
                    invNum,
                    $t('label.lbl_inquiryNumber'),
                    $t('label.lbl_invNumSid')
                  ),
                ]"
              ></v-text-field>
            </div>

            <!-- 空白 -->
            <span class="item-spacer">&nbsp;</span>

            <!-- 送り状番号 -->
            <div class="search-textbox-voucherNo">
              <v-text-field
                outlined
                dense
                v-model="invNum"
                :label="$t('label.lbl_invNumSid')"
                class="txt-single"
                clear-icon="mdi-close-circle"
                clearable
                hide-details
                :rules="[
                  rules.onlyOneInputRequired(
                    inquiryNumber,
                    invNum,
                    $t('label.lbl_inquiryNumber'),
                    $t('label.lbl_invNumSid')
                  ),
                ]"
              ></v-text-field>
            </div>
          </v-row>
          <v-row style="padding-top: 10px">
            <!-- 4段目 -->
            <div class="search-row-exeBtn">
              <!--ボタンエリア-->
              <div class="btn-search-area" style="float: left">
                <!-- 検索ボタン -->
                <v-btn color="primary" class="api-btn" @click="getApiList(true, true)">{{
                  $t("btn.btn_search")
                }}</v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div class="btn-area" style="float: right">
                <!--CSV出力ボタン-->
                <v-btn class="other-btn" @click="csvOutput()">
                  {{ $t("btn.btn_transportOrderCsvDown") }}
                </v-btn>
                <!--送り状発行ボタン-->
                <v-btn class="other-btn" @click="getInvoiceIssue()">
                  {{ $t("btn.btn_invPrint") }}
                </v-btn>
                <!-- 荷札発行ボタン-->
                <v-btn color="primary" class="api-btn" v-on:click="getTagIssue()">
                  {{ $t("btn.btn_shippingTagPrint") }}
                </v-btn>
              </div>
            </div>
          </v-row>
          <v-row>
            <div class="display-search-result-row" style="margin-top: 10px">
              <!--検索結果件数表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--ページング数-->
                <div class="text-label" style="clear: both; float: left">
                  <div>{{ $t("label.lbl_itemsPerPage") }}</div>
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                    mandatory
                  >
                    <v-btn class="pasing-btn" value="100" @click="u_100_on()" small>
                      {{ 100 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="250" @click="u_100_clear(250)" small>
                      {{ 250 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="500" @click="u_100_clear(500)" small>
                      {{ 500 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :ripple="false"
          :server-items-length="itemsPerPage"
          disable-filtering
          :hide-default-footer="true"
          height="510px"
          :options.sync="sortOptions"
        >
          <!-- ヘッダーチェックボックス -->
          <template v-slot:[`header.check`]="{ header }" class="product-fixed1">
            <input
              type="checkbox"
              :checked="isAllSelected"
              :value="header.check"
              @click="selectAllCheck"
              style="transform: scale(2)"
            />
          </template>
          <!-- 一覧チェックボックス -->
          <template v-slot:[`item.check`]="{ item, index }">
            <input
              type="checkbox"
              v-model="item.check"
              :value="listCheckbox"
              @click="checkList(item, index)"
              style="transform: scale(2)"
            />
          </template>
          <!-- 詳細ボタン -->
          <template v-slot:[`item.detail`]="{ item }">
            <v-btn small @click="openUpdateDelete(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <!-- 確認メッセージダイアログモーダル-->
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :firstPageFlag="infoDialog.firstPageFlag"
        :thirdPageFlag="infoDialog.thirdPageFlag"
      />
      <ConfirmDialog
        :isShow.sync="confirmDialog.isOpen"
        :message="confirmDialog.message"
        :screenFlag="confirmDialog.screenFlag"
        :okAction="confirmDialog.okAction"
        :redMessage="confirmDialog.redMessage"
        :changeFlag="confirmDialog.changeFlag"
      />

      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <!-- 警告ダイアログ -->
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- CSV出力確認ダイアログ -->
      <CsvOutputDialog
        :isShow.sync="csvOutputDialog.isOpen"
        :message="csvOutputDialog.message"
        :screenFlag="csvOutputDialog.screenFlag"
        :okAction="csvOutputDialog.okAction"
        :redMessage="csvOutputDialog.redMessage"
        :changeFlag="csvOutputDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import ConfirmDialog from "@/components/ConfirmDialog";
import CsvOutputDialog from "@/components/ConfirmDialog";
import NavBar from "../../components/NavBar.vue";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import { i18n } from "../../lang/lang.js";
import { commonFunction } from "../../assets/scripts/js/CommonFunction";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.MENU_ID.P_TOD_LIST,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    CsvOutputDialog,
    NavBar,
  },
  mixins: [commonMixin, commonRules],
  props: {
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    checkFlg: false,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    sortItem: "",
    sortOptions: {},
    u_100: false,
    // 取引先
    suppliersSelected: "",
    supplierList: [],
    supplierHint: "",
    // 発注日
    orderDateFrom: "",
    orderDateFromMenu: false,
    orderDateFromCal: "",
    orderDateTo: "",
    orderDateToMenu: false,
    orderDateToCal: "",
    // 輸送担当営業所
    officeSelected: "",
    officeList: [],
    productHintOffice: "",
    // 配車計画状況
    // pickupStatusDiv: "01",
    // deliveryStatusDiv: "01",
    pickupDispatchStatusDivList: [],
    deliveryDispatchStatusDivList: [],
    // 集荷元
    pickupPointSelected: "",
    pickupPointCd: "",
    pickupPointSid: "",
    pickupPointList: [],
    productHintPickup: "",
    // 集荷区分
    pickupDivSelected: "",
    pickupDivList: [],
    // 集荷予定日
    pickupScheduleDateFrom: "",
    pickupScheduleDateFromCal: "",
    pickupScheduleDateFromMenu: false,
    pickupScheduleDateTo: "",
    pickupScheduleDateToCal: "",
    pickupScheduleDateToMenu: false,
    // 配達先
    deliveryPointSelected: "",
    deliveryPointList: [],
    deliveryPointCd: "",
    deliveryPointSid: "",
    // 配達予定日
    deliveryScheduleDateFrom: "",
    deliveryScheduleDateFromCal: "",
    deliveryScheduleDateFromMenu: false,
    deliveryScheduleDateTo: "",
    deliveryScheduleDateToCal: "",
    deliveryScheduleDateToMenu: false,
    // お客様問合せ番号
    inquiryNumber: "",
    // 送り状番号
    invNum: "",
    // 表示件数
    totalCount: "",
    // ページング
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
    // 表示件数切替ボタン
    itemsPerPageBtn: "100",
    inputList: [],
    // 一覧選択チェックボックス
    listCheckbox: [],
    // 初期データ
    defaultData: [],
    headerItems: [
      {
        text: "選択",
        value: "check",
        width: "50px",
        align: "center",
        class: "product-fixed1",
        sortable: false,
      },
      {
        text: "No",
        value: "No",
        width: "60px",
        sortable: false,
        align: "center",
        class: "product-fixed2",
      },
      // 取引先
      {
        text: i18n.tc("label.lbl_supplier"),
        value: "supplier",
        width: "200px",
        align: "left",
      },
      // 集荷元
      {
        text: i18n.tc("label.lbl_pickupPoint"),
        value: "pickupPoint",
        width: "200px",
        align: "left",
      },
      // 集荷予定日
      {
        text: i18n.tc("label.lbl_pickupScheduleDate"),
        value: "pickupScheduleDate",
        width: "140px",
        align: "left",
      },
      // 集荷予定時間
      {
        text: i18n.tc("label.lbl_pickupScheduleTime"),
        value: "pickupScheduleTime",
        width: "160px",
        align: "left",
      },
      // 配達先
      {
        text: i18n.tc("label.lbl_deliveryPoint"),
        value: "deliveryPoint",
        width: "200px",
        align: "left",
      },
      // 配達予定日
      {
        text: i18n.tc("label.lbl_deliveryScheduleDate"),
        value: "deliveryScheduleDate",
        width: "140px",
        align: "left",
      },
      // 配達予定時間
      {
        text: i18n.tc("label.lbl_deliveryScheduleTime"),
        value: "deliveryScheduleTime",
        width: "160px",
        align: "left",
      },
      // 配車状況(集荷)
      {
        text: i18n.tc("label.lbl_pickupStatusDiv"),
        value: "pickupStatusDiv",
        width: "200px",
        align: "left",
      },
      // 配車状況(配達)
      {
        text: i18n.tc("label.lbl_deliveryStatusDiv"),
        value: "deliveryStatusDiv",
        width: "200px",
        align: "left",
      },
      // 対象／対象外
      {
        text: i18n.tc("label.lbl_vehiclePlanFlg"),
        value: "vehiclePlanFlg",
        width: "170px",
        align: "left",
      },
      // 公開／非公開
      {
        text: i18n.tc("label.lbl_privateFlg"),
        value: "privateFlg",
        width: "170px",
        align: "left",
      },
      // 発注日
      {
        text: i18n.tc("label.lbl_orderDate"),
        value: "orderDate",
        width: "140px",
        align: "left",
      },
      // 重量
      {
        text: i18n.tc("label.lbl_weight"),
        value: "weight",
        width: "200px",
        align: "right",
      },
      // 発注小口数
      {
        text: i18n.tc("label.lbl_smallLotQuantity"),
        value: "smallLotQuantity",
        width: "140px",
        align: "right",
      },
      // 送り状番号
      {
        text: i18n.tc("label.lbl_invNumSid"),
        value: "invNumSid",
        width: "200px",
        align: "left",
      },
      // お客様問い合わせ番号
      {
        text: i18n.tc("label.lbl_inquiryNumber"),
        value: "inquiryNum",
        width: "250px",
        align: "left",
      },
      // 送り状発行時間
      {
        text: i18n.tc("label.lbl_invoicePrintDate"),
        value: "invoicePrintDate",
        width: "180px",
        align: "center",
      },
      // 荷札発行時間
      {
        text: i18n.tc("label.lbl_taglabelPrintDate"),
        value: "taglabelPrintDate",
        width: "160px",
        align: "center",
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        width: "100px",
        align: "center",
        sortable: false,
      },
    ],
    // メニュー
    openMenu: null,
    supplierInput: "",
    getOfficeSelected: "",
    getPickupSelected: "",
    getDeliverySelected: "",
    // 全選択チェックボックス
    isAllSelected: false,
    updateDialogMessage: "",
    backMessage: "",
    searchParam: {
      searchSuppliersSelected: "",
      searchOrderDateFrom: "",
      searchOrderDateFromCal: "",
      searchOrderDateTo: "",
      searchOrderDateToCal: "",
      searchOfficeSelected: "",
      searchPickupStatusDiv: "",
      searchDeliveryStatusDiv: "",
      searchPickupPointCd: "",
      searchPickupPointSelected: "",
      searchPickupScheduleDateFrom: "",
      searchPickupScheduleDateFromCal: "",
      searchPickupScheduleDateTo: "",
      searchPickupScheduleDateToCal: "",
      searchDeliveryPointCd: "",
      searchDeliveryPointSelected: "",
      searchDeliveryScheduleDateFrom: "",
      searchDeliveryScheduleDateFromCal: "",
      searchDeliveryScheduleDateTo: "",
      searchDeliveryScheduleDateToCal: "",
      searchInquiryNumber: "",
      supplierList: "",
      supplierHint: "",
      officeList: "",
      productHintOffice: "",
      pickupPointList: "",
      productHintPickup: "",
      deliveryPointList: "",
      productHintDelivery: "",
    },
    // 編集用チェックボックスリスト
    chengeListCheckbox: [],
    listCheckboxBtn: [],
    // バリデーションチェックメッセージダイアログ
    alertMessage: "",
    pickupAlertMessage: "",
    deliveryAlertMessage: "",
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
    confirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
    csvOutputDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
      okAction: () => {},
    },
  }),

  created() {
    if (this.$route.params.searchParam) {
      this.searchParam = this.$route.params.searchParam;
      this.suppliersSelected = this.searchParam.searchSuppliersSelected;
      this.supplierHint = this.searchParam.supplierHint;
      this.orderDateFrom = this.searchParam.searchOrderDateFrom;
      this.orderDateFromCal = this.searchParam.searchOrderDateFromCal;
      this.orderDateTo = this.searchParam.searchOrderDateTo;
      this.orderDateToCal = this.searchParam.searchOrderDateToCal;
      this.officeList = this.searchParam.officeList;
      this.officeSelected = this.searchParam.searchOfficeSelected;
      this.productHintOffice = this.searchParam.productHintOffice;
      // this.pickupStatusDiv = this.searchParam.searchPickupStatusDiv;
      // this.deliveryStatusDiv = this.searchParam.searchDeliveryStatusDiv;
      this.pickupPointCd = this.searchParam.searchPickupPointCd;
      this.pickupPointSelected = this.searchParam.searchPickupPointSelected;
      this.productHintPickup = this.searchParam.productHintPickup;
      this.pickupScheduleDateFrom = this.searchParam.searchPickupScheduleDateFrom;
      this.pickupScheduleDateFromCal = this.searchParam.searchPickupScheduleDateFromCal;
      this.pickupScheduleDateTo = this.searchParam.searchPickupScheduleDateTo;
      this.pickupScheduleDateToCal = this.searchParam.searchPickupScheduleDateToCal;
      this.deliveryPointCd = this.searchParam.searchDeliveryPointCd;
      this.deliveryPointSelected = this.searchParam.searchDeliveryPointSelected;
      this.productHintDelivery = this.searchParam.productHintDelivery;
      this.deliveryScheduleDateFrom = this.searchParam.searchDeliveryScheduleDateFrom;
      this.deliveryScheduleDateFromCal = this.searchParam.searchDeliveryScheduleDateFromCal;
      this.deliveryScheduleDateTo = this.searchParam.searchDeliveryScheduleDateTo;
      this.deliveryScheduleDateToCal = this.searchParam.searchDeliveryScheduleDateToCal;
      this.inquiryNumber = this.searchParam.searchInquiryNumber;
      this.invNum = this.searchParam.searchInvNum;
      this.supplierList = this.searchParam.supplierList;
      this.pickupPointList = this.searchParam.pickupPointList;
      this.deliveryPointList = this.searchParam.deliveryPointList;
      this.checkFlg = this.$route.params.checkFlg;

      this.getApiList(true, true);
    }
  },

  methods: {
    link() {
      location.href = this.returnURL;
    },
    u_100_on() {
      this.u_100 = true;
      this.itemsPerPage = 100;
      this.page = 1;
      this.getApiList(false, false);
    },
    u_100_clear(val) {
      this.u_100 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.getApiList(false, false);
    },

    /**
     * 受注一覧画面：全選択チェックボックス処理
     */
    selectAllCheck() {
      if (!this.isAllSelected) {
        this.listCheckbox = [];
        this.listCheckbox = true;
        this.isAllSelected = true;
        for (var i = 0; i < this.inputList.length; i++) {
          this.inputList[i].check = true;
          this.listCheckboxBtn.push(this.inputList[i]);
        }
      } else {
        this.listCheckbox = [];
        this.listCheckbox = false;
        for (var j = 0; j < this.inputList.length; j++) {
          this.inputList[j].check = false;

          // 画面遷移用リストを初期化
          this.listCheckboxBtn = [];
        }
        this.isAllSelected = false;
      }
    },

    /**
     * 初期データ取得
     */
    getCustomInfo(val) {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      const params = {};
      // 取引先コード（前方一致）
      params.supplierCode = val;

      const clientList = getParameter.getTmsBizCustomerMst(params);
      // const clientList = [
      //   {
      //     value: "4400710",
      //     text: "4400710（ボルボ・カー・ジャパン㈱埼玉サービスセンター）",
      //     name: "ボルボ・カー・ジャパン㈱埼玉サービスセンター",
      //   },
      // ];
      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];

          if (this.$route.params.searchSuppliersSelected) {
            // this.getSuppliersSelected();
          }
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    getMstCode() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // ------- ↓↓共通処理が完成次第、修正↓↓-----------
      // 配車状況（集荷）
      const pickupDispatchStatusList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.PICKUP_DISPATCH_STATUS_DIV,
      });
      // 配車状況（配達）
      const deliveryDispatchStatusList = getParameter.getTmsCodeMst({
        codeType: appConfig.CODETYPE.DELIVERY_DISPATCH_STATUS_DIV,
      });
      // const dispatchStatusList = [
      //   {
      //     text: "未配車",
      //     value: "01",
      //   },
      //   {
      //     text: "配車",
      //     value: "02",
      //   },
      // ];

      Promise.all([pickupDispatchStatusList, deliveryDispatchStatusList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.pickupDispatchStatusDivList = result[0];
          this.deliveryDispatchStatusDivList = result[1];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    /**
     * 受注一覧画面： 検索ボタン押下処理
     */
    getApiList(checkBoxFlag, isSearchConditionUpdate) {
      // 入力チェック結果フラグ
      let inputCheckResult = false;
      let check = 0;
      // メッセージ初期化
      this.alertMessage = "";
      this.pickupAlertMessage = "";
      this.deliveryAlertMessage = "";
      // 発注日FromToの日付、入力チェック
      if (new Date(this.orderDateFrom).getTime() > new Date(this.orderDateTo).getTime()) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }
      // 集荷予定日FromToの日付、入力チェック
      if (this.pickupScheduleDateFrom && this.pickupScheduleDateTo) {
        if (
          new Date(this.pickupScheduleDateFrom).getTime() >
          new Date(this.pickupScheduleDateTo).getTime()
        ) {
          this.pickupAlertMessage = i18n.tc("check.chk_inputFromVali");
          check++;
        }
      }
      // 配達予定日FromToの日付、入力チェック
      if (this.deliveryScheduleDateFrom && this.deliveryScheduleDateTo) {
        if (
          // FromToの日付、入力チェック
          new Date(this.deliveryScheduleDateFrom).getTime() >
          new Date(this.deliveryScheduleDateTo).getTime()
        ) {
          this.deliveryAlertMessage = i18n.tc("check.chk_inputFromVali");
          check++;
        }
      }

      if (check == 0) {
        /**
         * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
         * エラーがでるので回避するためこのようなコードとなっている。
         */
        if (this.$route.params.searchParam && this.checkFlg) {
          // 登録画面からの遷移の場合、入力チェックは実施しない。
          inputCheckResult = true;
        } else {
          // 上記外の場合はチェックを行う。
          inputCheckResult = this.$refs.form.validate();
        }
        // 画面遷移フラグをfalseに戻す
        this.checkFlg = false;

        if (inputCheckResult) {
          // バリデーションエラーメッセージを非表示
          this.alertMessage = "";
          this.pickupAlertMessage = "";
          this.deliveryAlertMessage = "";

          // 初期検索かページング検索か判断
          if (checkBoxFlag) {
            this.isAllSelected = false;
          }
          // 検索条件を保存
          if (this.suppliersSelected) {
            this.searchParam.searchSuppliersSelected = this.suppliersSelected;
          } else {
            this.searchParam.searchSuppliersSelected = "";
          }
          this.searchParam.supplierHint = this.supplierHint;
          this.searchParam.searchOrderDateFrom = this.orderDateFrom;
          this.searchParam.searchOrderDateFromCal = this.orderDateFromCal;
          this.searchParam.searchOrderDateTo = this.orderDateTo;
          this.searchParam.searchOrderDateToCal = this.orderDateToCal;
          if (this.officeSelected) {
            this.searchParam.searchOfficeSelected = this.officeSelected;
          } else {
            this.searchParam.searchOfficeSelected = "";
          }
          this.searchParam.productHintOffice = this.productHintOffice;
          // this.searchParam.searchPickupStatusDiv = this.pickupStatusDiv;
          // this.searchParam.searchDeliveryStatusDiv = this.deliveryStatusDiv;
          this.searchParam.searchPickupPointCd = this.pickupPointCd;
          if (this.pickupPointSelected) {
            this.searchParam.searchPickupPointSelected = this.pickupPointSelected;
          } else {
            this.searchParam.searchPickupPointSelected = "";
          }
          this.searchParam.productHintPickup = this.productHintPickup;
          this.searchParam.searchPickupScheduleDateFrom = this.pickupScheduleDateFrom;
          this.searchParam.searchPickupScheduleDateFromCal = this.pickupScheduleDateFromCal;
          this.searchParam.searchPickupScheduleDateTo = this.pickupScheduleDateTo;
          this.searchParam.searchPickupScheduleDateToCal = this.pickupScheduleDateToCal;
          this.searchParam.searchDeliveryPointCd = this.deliveryPointCd;
          if (this.deliveryPointSelected) {
            this.searchParam.searchDeliveryPointSelected = this.deliveryPointSelected;
          } else {
            this.searchParam.searchDeliveryPointSelected = "";
          }
          this.searchParam.productHintDelivery = this.productHintDelivery;
          this.searchParam.searchDeliveryScheduleDateFrom = this.deliveryScheduleDateFrom;
          this.searchParam.searchDeliveryScheduleDateFromCal = this.deliveryScheduleDateFromCal;
          this.searchParam.searchDeliveryScheduleDateTo = this.deliveryScheduleDateTo;
          this.searchParam.searchDeliveryScheduleDateToCal = this.deliveryScheduleDateToCal;
          if (this.inquiryNumber) {
            this.searchParam.searchInquiryNumber = this.inquiryNumber;
          } else {
            this.searchParam.searchInquiryNumber = "";
          }
          if (this.invNum) {
            this.searchParam.searchInvNum = this.invNum;
          } else {
            this.searchParam.searchInvNum = "";
          }
          this.searchParam.supplierList = this.supplierList;
          this.searchParam.officeList = this.officeList;
          this.searchParam.pickupPointList = this.pickupPointList;
          this.searchParam.deliveryPointList = this.deliveryPointList;

          // ローディング画面表示ON
          this.loadingCounter = 1;

          // httpClientで使用する共通IFパラメータ等を作成する。
          const config = this.$httpClient.createGetApiRequestConfig();
          // 取引先
          if (this.suppliersSelected) {
            config.params.shipperClientCd = this.suppliersSelected;
          }
          // 発注日From
          if (this.orderDateFrom) {
            config.params.orderFromDate = dateTimeHelper.convertUTC(this.orderDateFrom);
          }
          // 発注日To
          if (this.orderDateTo) {
            config.params.orderToDate = dateTimeHelper.convertUTC(
              this.orderDateTo + " 23:59:59.999"
            );
          }
          // 輸送担当営業所SID
          if (this.officeSelected) {
            config.params.officeSid = this.officeSelected;
          }
          // 集荷状態区分
          // config.params.pickupStatusDiv = this.pickupStatusDiv;
          // // 配達状態区分
          // config.params.deliveryStatusDiv = this.deliveryStatusDiv;
          // 集荷拠点取引先CD
          if (this.pickupPointCd) {
            config.params.pickupPointCd = this.pickupPointCd;
          }
          // 集荷拠点SID
          if (this.pickupPointSid) {
            config.params.pickupPointSid = this.pickupPointSid;
          } else if (this.pickupPointSelected) {
            config.params.pickupPointSid = this.pickupPointSelected;
          }
          // 集荷予定日From
          if (this.pickupScheduleDateFrom) {
            config.params.pickupScheduleFromDate = dateTimeHelper.convertUTC(
              this.pickupScheduleDateFrom
            );
          }
          // 集荷予定日To
          if (this.pickupScheduleDateTo) {
            config.params.pickupSchedulToDate = dateTimeHelper.convertUTC(
              this.pickupScheduleDateTo + " 23:59:59.999"
            );
          }
          // 配達拠点取引先CD
          if (this.deliveryPointCd) {
            config.params.deliveryPointCd = this.deliveryPointCd;
          }
          // 配達拠点SID
          if (this.deliveryPointSid) {
            config.params.deliveryPointSid = this.deliveryPointSid;
          } else if (this.deliveryPointSelected) {
            config.params.deliveryPointSid = this.deliveryPointSelected;
          }
          // 配達予定日From
          if (this.deliveryScheduleDateFrom) {
            config.params.deliveryScheduleFromDate = dateTimeHelper.convertUTC(
              this.deliveryScheduleDateFrom
            );
          }
          // 配達予定日To
          if (this.deliveryScheduleDateTo) {
            config.params.deliveryScheduleToDate = dateTimeHelper.convertUTC(
              this.deliveryScheduleDateTo + " 23:59:59.999"
            );
          }
          // お客様問合せ番号
          if (this.inquiryNumber) {
            config.params.inquiryNumber = this.inquiryNumber;
          }
          // 送り状SID
          if (this.invNum) {
            config.params.invNumSid = this.invNum;
          }

          // ページャー処理
          if ((this.page >= 2 && this.inputList.length == 0) || isSearchConditionUpdate) {
            config.params.reqComPageIndex = "1";
            this.page = 1;
          } else {
            config.params.reqComPageIndex = this.page;
          }
          // ページング
          config.params.reqComPaginationFlg = "1";
          config.params.reqComPageLimit = this.itemsPerPage;

          if (this.sortItem != "") {
            config.params.reqComSortItem = this.sortItem;
          }

          return new Promise((resolve, reject) => {
            this.$httpClient
              // 接続先のAPI_URLを入力
              .secureGet(appConfig.API_URL.BIZ_TRANSPORT_SEARCH, config)
              .then((response) => {
                // console.debug("getApiList(checkBoxFlag) Response", response);
                const jsonData = JSON.parse(JSON.stringify(response.data));

                // 成功
                if (jsonData.resCom.resComCode == "000") {
                  // ↓↓-------仮置きでテストデータを表示 API完成次第、ここは削除=-------↓↓
                  // const orders = [];
                  // for (let i = 1; i <= 100; i++) {
                  //   orders.push({
                  //     supplierName: "取引先名テスト" + i,
                  //     pickupBaseName: "集荷元名テスト",
                  //     pickupScheduleDate: "2024/07/10 00:00:00.000",
                  //     pickupScheduleTime: "01:00 - 02:00",
                  //     deliveryBaseName: "配達先テスト",
                  //     deliveryScheduleDate: "2024/07/11 00:00:00.000",
                  //     deliveryScheduleTime: "10:00 - 13:00",
                  //     pickupStatusName: "配車済",
                  //     deliveryStatusName: "未配車",
                  //     vehiclePlanName: "対象",
                  //     privateName: "非公開",
                  //     orderDate: "2024/07/12 00:00:00.000",
                  //     totalWeight: "30000.333",
                  //     smallLotsQuantity: "50000",
                  //     invNumSid: "1234567890",
                  //     inquiryNumber: "9876543210",
                  //     invoicePrintDate: "2024/07/13 00:00:00.000",
                  //     taglabelPrintDate: "2024/07/14 00:00:00.000",
                  //   });
                  // }
                  // const jsonData = {
                  //   resIdv: {
                  //     orders,
                  //   },
                  //   resCom: {
                  //     resComPagination: {
                  //       totalPage: 1,
                  //       totalRecord: 100,
                  //     },
                  //   },
                  // };
                  // ↑↑---------------------------------------------------------↑↑
                  this.pageCount = jsonData.resCom.resComPagination.totalPage;
                  this.totalCount = jsonData.resCom.resComPagination.totalRecord;
                  const list = [];
                  // 受注一覧を取得
                  jsonData.resIdv.orders.forEach((row) => {
                    list.push({
                      No: jsonData.resIdv.orders.indexOf(row) + 1,
                      // 取引先
                      supplier: row.supplierName,
                      // 集荷元
                      pickupPoint: row.pickupBaseName,
                      // 集荷予定日（画面表示用=JST:秒数なし）
                      pickupScheduleDate: dateTimeHelper
                        .convertUTC2JST(row.pickupScheduleDate)
                        .substr(0, 10),
                      // 集荷予定時間
                      pickupScheduleTime: row.pickupScheduleTime,
                      // 配達先
                      deliveryPoint: row.deliveryBaseName,
                      // 配達予定日（画面表示用=JST:秒数なし）
                      deliveryScheduleDate: dateTimeHelper
                        .convertUTC2JST(row.deliveryScheduleDate)
                        .substr(0, 10),
                      // 配達予定時間
                      deliveryScheduleTime: row.deliveryScheduleTime,
                      // 配車状況（集荷）
                      pickupStatusDiv: row.pickupStatusName,
                      // 配車状況（配達）
                      deliveryStatusDiv: row.deliveryStatusName,
                      // 対象/対象外
                      vehiclePlanFlg: row.vehiclePlanName,
                      // 公開/非公開
                      privateFlg: row.privateName,
                      // 発注日（画面表示用=JST:秒数なし）
                      orderDate: dateTimeHelper.convertUTC2JST(row.orderDate).substr(0, 10),
                      // 重量
                      weight: commonFunction.getDelimiterFew(row.totalWeight, 9, 3),
                      // 小口数
                      smallLotQuantity: commonFunction.getDelimiter(row.smallLotsQuantity, 5),
                      // 送り状番号
                      invNumSid: row.invNumSid,
                      // お客様問合せ番号
                      inquiryNum: row.inquiryNumber,
                      // 送り状発行時間
                      invoicePrintDate:
                        row.invoicePrintDate != null
                          ? dateTimeHelper.convertUTC2JST(row.invoicePrintDate).substr(0, 19)
                          : "",
                      // 荷札発行時間
                      taglabelPrintDate:
                        row.taglabelPrintDate != null
                          ? dateTimeHelper.convertUTC2JST(row.taglabelPrintDate).substr(0, 19)
                          : "",
                    });
                  });
                  this.inputList = list;

                  if (!checkBoxFlag) {
                    // 検索ボタン以外で検索処理が走った場合、元々チェックしていたレコードにチェックをいれる
                    for (var k = 0; k < this.inputList.length; k++) {
                      for (var j = 0; j < this.listCheckboxBtn.length; j++) {
                        if (this.inputList[k].No == this.listCheckboxBtn[j].No) {
                          this.inputList[k].check = true;
                        }
                      }
                    }
                  } else {
                    // 検索ボタン押下時は、チェックボックスを詰めていたListを初期化する
                    this.listCheckboxBtn.length = 0;
                  }

                  // ページング検索の場合の全選択チェックボックス処理
                  if (this.isAllSelected) {
                    this.isAllSelected = true;
                    for (var i = 0; i < this.inputList.length; i++) {
                      this.inputList[i].check = true;
                    }
                  }
                  // this.loadingCounter = 0; // API完成次第、この行も削除
                  resolve(response);
                } else {
                  // エラー時
                  this.infoDialog.message = jsonData.resCom.resComMessage;
                  this.infoDialog.title = appConfig.DIALOG.title;
                  this.infoDialog.isOpen = true;
                  this.infoDialog.firstPageFlag = true;
                  reject();
                }
              })
              .catch((ex) => {
                this.infoDialog.message = ex;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              })
              .finally(() => {
                // ローディング画面表示OFF
                this.loadingCounter = 0;
              });
          });
        }
      }
    },

    /**
     * 受注一覧画面： CSV出力ボタン押下
     */
    csvOutput() {
      if (!this.csvOutputCheck()) {
        return;
      }
      this.csvOutputDialog.message = messsageUtil.getMessage("P-TOD-002_001_C");
      this.csvOutputDialog.isOpen = true;
      this.csvOutputDialog.screenFlag = true;
      this.csvOutputDialog.changeFlag = false;
      this.csvOutputDialog.okAction = this.csvOutputOk;
    },
    /**
     * 日付大小比較
     */
    compDate(dateF, dateT) {
      if (dateF && dateT) {
        if (new Date(dateF).getTime() > new Date(dateT).getTime()) {
          return false;
        }
      }
      return true;
    },
    /**
     * CSVを出力する時の検索条件の入力チェック
     */
    csvOutputCheck() {
      // 単項目チェック
      if (!this.$refs.form.validate()) {
        return false;
      }

      // 相関チェック
      let check = 0;

      // メッセージ初期化
      this.alertMessage = "";
      this.pickupAlertMessage = "";
      this.deliveryAlertMessage = "";

      // 発注日Toは発注日Fromよりも未来の日付を入力してください。
      if (!this.compDate(this.orderDateFrom, this.orderDateTo)) {
        this.alertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }

      // 集荷予定日Toは集荷予定日Fromよりも未来の日付を入力してください。
      if (!this.compDate(this.pickupScheduleDateFrom, this.pickupScheduleDateTo)) {
        this.pickupAlertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }

      // 配達予定日Toは配達予定日Fromよりも未来の日付を入力してください。
      if (!this.compDate(this.deliveryScheduleDateFrom, this.deliveryScheduleDateTo)) {
        this.deliveryAlertMessage = i18n.tc("check.chk_inputFromVali");
        check++;
      }

      if (check > 0) {
        return false;
      }

      return true;
    },
    /**
     * 受注一覧画面： CSV出力開始
     */
    csvOutputOk() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const body = this.$httpClient.createRequestBodyConfig();
      // 共通パラメータの設定
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_002;
      //body.reqCom.reqComComponentId = "A-TOD-007";

      // 検索条件の設定
      // 取引先SID
      body.reqCom.reqComOfficeSid = sessionStorage.getItem("sales_office_sid");
      // 取引先CD
      if (this.suppliersSelected) {
        body.reqIdv.shipperClientCd = this.suppliersSelected;
      }
      // 発注日From
      if (this.orderDateFrom) {
        body.reqIdv.orderFromDate = dateTimeHelper.convertUTC(this.orderDateFrom);
      }
      // 発注日To
      if (this.orderDateTo) {
        body.reqIdv.orderToDate = dateTimeHelper.convertUTC(this.orderDateTo + " 23:59:59.999");
      }
      // 輸送担当営業所SID
      if (this.officeSelected) {
        body.reqIdv.officeSid = this.officeSelected;
      }
      // 集荷状態区分
      // body.reqIdv.pickupStatusDiv = this.pickupStatusDiv;
      // 配達状態区分
      // body.reqIdv.deliveryStatusDiv = this.deliveryStatusDiv;

      // 集荷拠点取引先CD
      if (this.pickupPointCd) {
        body.reqIdv.pickupPointCd = this.pickupPointCd;
      }
      // 集荷拠点SID
      if (this.pickupPointSid) {
        body.reqIdv.pickupPointSid = this.pickupPointSid;
      } else if (this.pickupPointSelected) {
        body.reqIdv.pickupPointSid = this.pickupPointSelected;
      }
      // 集荷予定日From
      if (this.pickupScheduleDateFrom) {
        body.reqIdv.pickupScheduleFromDate = dateTimeHelper.convertUTC(this.pickupScheduleDateFrom);
      }
      // 集荷予定日To
      if (this.pickupScheduleDateTo) {
        body.reqIdv.pickupScheduleToDate = dateTimeHelper.convertUTC(
          this.pickupScheduleDateTo + " 23:59:59.999"
        );
      }
      // 配達拠点取引先CD
      if (this.deliveryPointCd) {
        body.reqIdv.deliveryPointCd = this.deliveryPointCd;
      }
      // 配達拠点SID
      if (this.deliveryPointSid) {
        body.reqIdv.deliveryPointSid = this.deliveryPointSid;
      } else if (this.deliveryPointSelected) {
        body.reqIdv.deliveryPointSid = this.deliveryPointSelected;
      }
      // 配達予定日From
      if (this.deliveryScheduleDateFrom) {
        body.reqIdv.deliveryScheduleFromDate = dateTimeHelper.convertUTC(
          this.deliveryScheduleDateFrom
        );
      }
      // 配達予定日To
      if (this.deliveryScheduleDateTo) {
        body.reqIdv.deliveryScheduleToDate = dateTimeHelper.convertUTC(
          this.deliveryScheduleDateTo + " 23:59:59.999"
        );
      }
      // お客様問合せ番号
      if (this.inquiryNumber) {
        body.reqIdv.inquiryNumber = this.inquiryNumber;
      }
      // 送り状SID
      if (this.invNum) {
        body.reqIdv.invNumSid = this.invNum;
      }

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力(受注CSV出力)
          .securePost(appConfig.API_URL.BIZ_TRANSPORT_ORDERS_CSV, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 返却されたurlを共通関数へ呼び出し
              commonFunction.getLink(jsonData.resIdv.filePath);
              resolve(response);
            } else {
              // エラー時(件数0件またはapiエラー時)
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              resolve(response);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
            this.dataOutPutDialog = false;
          });
      });
    },

    /**
     * 受注一覧画面： 送り状発行API
     */
    getInvoiceIssue() {
      if (this.listCheckboxBtn.length > 0) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();
        // 共通IF項目 画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_002;
        // タイムゾーン
        body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
        // 受注情報List
        var bodyList = [];
        for (var i = 0; i < this.listCheckboxBtn.length; i++) {
          var inputMap = {};
          // 送り状SID
          inputMap.invNumSid = this.listCheckboxBtn[i].invNumSid;
          bodyList.push(inputMap);
        }
        body.reqIdv.invNumSids = bodyList;
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_TRANSPORT_INVOICE, body, appConfig.APP_CONFIG)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 成功
              if (jsonData.resCom.resComCode == "000") {
                // 返却されたurlをデータに格納、ダウンロードボタンの活性化
                this.returnURL = jsonData.resIdv.filePath;
                this.link();
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      } else {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      }
    },

    /**
     * 警告ダイアログ、OKボタン押下
     */
    updateOk() {
      this.updateDialogMessage = false;
    },

    /**
     * 受注一覧画面： 荷札発行API
     */
    getTagIssue() {
      if (this.listCheckboxBtn.length > 0) {
        // ローディング画面表示ON
        this.loadingCounter = 1;
        // httpClientで使用する共通IFパラメータ等を作成する。
        const body = this.$httpClient.createRequestBodyConfig();
        // 共通IF項目 画面ID
        body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_TOD_002;
        // タイムゾーン
        body.reqIdv.timeZone = dateTimeHelper.getTimeZone();
        // 受注情報List
        var bodyList = [];
        for (var i = 0; i < this.listCheckboxBtn.length; i++) {
          var inputMap = {};
          // 送り状SID
          inputMap.invNumSid = this.listCheckboxBtn[i].invNumSid;
          bodyList.push(inputMap);
        }
        body.reqIdv.taglabel = bodyList;
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            .securePost(appConfig.API_URL.BIZ_TRANSPORT_TAGLABEL, body, appConfig.APP_CONFIG)
            .then((response) => {
              const jsonData = JSON.parse(JSON.stringify(response.data));
              // 成功
              if (jsonData.resCom.resComCode == "000") {
                // 返却されたurlをデータに格納、ダウンロードボタンの活性化
                this.returnURL = jsonData.resIdv.filePath;
                this.link();
                resolve(response);
              } else {
                // エラー時
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      } else {
        // 警告ダイアログ表示
        this.updateDialogMessage = true;
        this.backMessage = messsageUtil.getMessage("P-EXT-003_001_E");
      }
    },

    /**
     * 初期値
     */
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);
      if (this.$route.params.searchParam) {
        // console.debug(this.suppliersSelected);
        // 遷移フラグ
        this.transitionFlg = true;
      } else {
        this.suppliersSelected = "";
        this.orderDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.orderDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.officeSelected = "";
        // this.pickupStatusDiv = "01";
        // this.deliveryStatusDiv = "01";
        this.pickupPointSelected = "";
        this.pickupScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.pickupScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryPointSelected = "";
        this.deliveryScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.inquiryNumber = "";
        this.invNum = "";
      }
      this.getMstCode();
    },

    // ↓↓------------機能追加され次第実装(項目名も要修正)----------------
    /**
     * 受注一覧画面：受注修正・削除画面を開きます。
     */
    openUpdateDelete(item) {
      // 選択した行のアイテム
      this.editedIndex = this.inputList.indexOf(item);
      // 修正画面へ渡す値を設定
      this.$router.push({
        name: appConfig.SCREEN_ID.P_TOD_003,
        params: {
          // 送り状番号
          invNumSid: this.inputList[this.editedIndex].invNumSid,

          // 検索条件
          searchParam: this.searchParam,
        },
      });
    },
    // ↑↑-----------------------------------------------

    /**
     * チェックボックス押下処理
     */
    checkList(item, index) {
      if (!item.check) {
        // チェックを入れた場合

        // 編集用リスト
        this.chengeListCheckbox.push(item);

        // 画面遷移用リスト
        this.listCheckboxBtn.push(item);
      } else {
        // チェックを外した場合

        // 外したレコードの送り状SIDを取得
        let inputListOutlistNo = this.inputList[index].invNumSid;

        // 画面遷移用リストを初期化
        this.listCheckboxBtn = [];

        // チェックを外したレコード以外を画面遷移用リストに詰めなおす
        for (var i = 0; i < this.chengeListCheckbox.length; i++) {
          if (this.chengeListCheckbox[i].invNumSid != inputListOutlistNo) {
            this.listCheckboxBtn.push(this.chengeListCheckbox[i]);
          }
        }

        // 編集用リストも更新
        this.chengeListCheckbox = [];
        for (var j = 0; j < this.listCheckboxBtn.length; j++) {
          this.chengeListCheckbox.push(this.listCheckboxBtn[j]);
        }
      }
    },

    /**
     * メニューダイアログ
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 日付Fromを－１日します。
     */
    prevFromDate(param) {
      switch (param) {
        case "order":
          this.orderDateFromCal = commonFunction.addDate(this.orderDateFromCal, "");
          break;
        case "pickup":
          this.pickupScheduleDateFromCal = commonFunction.addDate(
            this.pickupScheduleDateFromCal,
            ""
          );
          break;
        case "delivery":
          this.deliveryScheduleDateFromCal = commonFunction.addDate(
            this.deliveryScheduleDateFromCal,
            ""
          );
          break;
      }
    },

    /**
     * 日付Fromを＋１日します。
     */
    nextFromDate(param) {
      switch (param) {
        case "order":
          this.orderDateFromCal = commonFunction.addDate(this.orderDateFromCal, "add");
          break;
        case "pickup":
          this.pickupScheduleDateFromCal = commonFunction.addDate(
            this.pickupScheduleDateFromCal,
            "add"
          );
          break;
        case "delivery":
          this.deliveryScheduleDateFromCal = commonFunction.addDate(
            this.deliveryScheduleDateFromCal,
            "add"
          );
          break;
      }
    },
    /**
     * 日付Toを－１日します。
     */
    prevToDate(param) {
      switch (param) {
        case "order":
          this.orderDateToCal = commonFunction.addDate(this.orderDateToCal, "");
          break;
        case "pickup":
          this.pickupScheduleDateToCal = commonFunction.addDate(this.pickupScheduleDateToCal, "");
          break;
        case "delivery":
          this.deliveryScheduleDateToCal = commonFunction.addDate(
            this.deliveryScheduleDateToCal,
            ""
          );
          break;
      }
    },

    /**
     * 日付Toを＋１日します。
     */
    nextToDate(param) {
      switch (param) {
        case "order":
          this.orderDateToCal = commonFunction.addDate(this.orderDateToCal, "add");
          break;
        case "pickup":
          this.pickupScheduleDateToCal = commonFunction.addDate(
            this.pickupScheduleDateToCal,
            "add"
          );
          break;
        case "delivery":
          this.deliveryScheduleDateToCal = commonFunction.addDate(
            this.deliveryScheduleDateToCal,
            "add"
          );
          break;
      }
    },

    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      // 日付の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;

      // 対象の文字列チェック
      if (regex.test(date)) {
        // 上記の日付の形で入ってきた場合、そのまま返却。（手入力した場合）
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month, day] = date.split("-");
        return `${year}/${month}/${day}`;
      }
    },

    /**
     * 日付入力(From)
     */
    changeDateFrom(val, param) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      var formatedDt = null;
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        formatedDt = dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(strDt.substr(0, 10)));
      }
      switch (param) {
        case "order":
          this.orderDateFromCal = formatedDt;
          break;
        case "pickup":
          this.pickupScheduleDateFromCal = formatedDt;
          break;
        case "delivery":
          this.deliveryScheduleDateFromCal = formatedDt;
          break;
      }
    },

    changecreateDateFrom(val) {
      this.createDateFromCal = commonFunction.directInput(val);
    },

    /**
     * 日付入力(To)
     */
    changeDateTo(val, param) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      var formatedDt = null;
      if (inputVal.length == 8) {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(4, 2) + "/" + inputVal.substr(6, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "/" + inputVal.substr(5, 2) + "/" + inputVal.substr(8, 2);
      }
      if (dateTimeHelper.validDate(strDt, "/")) {
        formatedDt = dateTimeHelper.dateCalc(dateTimeHelper.convertUTC2JST(strDt.substr(0, 10)));
      }
      switch (param) {
        case "order":
          this.orderDateToCal = formatedDt;
          break;
        case "pickup":
          this.pickupScheduleDateToCal = formatedDt;
          break;
        case "delivery":
          this.deliveryScheduleDateToCal = formatedDt;
          break;
      }
    },

    /**
     * 受注一覧画面：取引先コードリストボックス取得処理
     */
    setSrhSupplier() {
      return commonFunction.getListSetName(this.supplierList, this.suppliersSelected);
    },

    /**
     * 受注一覧画面：輸送担当営業所コードリストボックス取得処理
     */
    setSrhOffice() {
      return commonFunction.getListSetName(this.officeList, this.officeSelected);
    },
    changeSupplier(value) {
      let val = this.supplierList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.supplierHint = val.text;
      }
      this.supplierList = this.supplierList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.supplierHint = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 輸送担当営業所 プルダウンチェンジイベント
     */
    changeOffice(value) {
      // 輸送担当営業所を選択時
      let val = this.officeList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintOffice = val.text;
        this.getOfficeSelected = val.text;
      }
      this.officeList = this.officeList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintOffice = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 配達先 プルダウンチェンジイベント
     */
    changeDelivery(value) {
      // 配達先を選択時
      let val = this.deliveryPointList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintDelivery = val.text;
        this.getDeliverySelected = val.text;
        this.deliveryPointCd = val.customerCode;
        this.deliveryPointSid = val.value;
      }
      this.deliveryPointList = this.deliveryPointList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintDelivery = "";
        this.deliveryPointCd = "";
        this.deliveryPointSid = "";
      } else {
        // 処理なし
      }
    },

    /**
     * 受注一覧画面：集荷元コードリストボックス取得処理
     */
    setSrhPickup() {
      return commonFunction.getListSetName(this.pickupPointList, this.pickupPointSelected);
    },

    /**
     * 受注一覧画面：配達先コードリストボックス取得処理
     */
    setSrhDelivery() {
      return commonFunction.getListSetName(this.deliveryPointList, this.deliveryPointSelected);
    },

    /**
     * 集荷先 プルダウンチェンジイベント
     */
    changePickup(value) {
      // 集荷先を選択時
      let val = this.pickupPointList.find((v) => v.value == value);
      if ((val ?? false) && value != "") {
        this.productHintPickup = val.text;
        this.getPickupSelected = val.text;
        this.pickupPointCd = val.customerCode;
        this.pickupPointSid = val.value;
      }
      this.pickupPointList = this.pickupPointList.filter((v) => v.value == value);
      // 値が設定されていない場合はクリアする
      if (value == "" || value == null) {
        this.productHintPickup = "";
        this.pickupPointCd = "";
        this.pickupPointSid = "";
      } else {
        // 処理なし
      }
    },
    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.confirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.confirmDialog.isOpen = true;
      this.confirmDialog.screenFlag = true;
      this.confirmDialog.changeFlag = false;
      this.confirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // 画面遷移後の場合
      if (this.transitionFlg) {
        this.suppliersSelected = "";
        this.supplierList = [];
        this.officeSelected = "";
        this.officeList = [];
        this.pickupPointSelected = "";
        this.pickupPointList = [];
        this.deliveryPointSelected = "";
        this.deliveryPointList = [];
        this.inquiryNumber = "";
        this.invNum = "";

        // 初期化状態の設定
        this.orderDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.orderDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.orderDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.pickupScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.pickupScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.pickupScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryScheduleDateFrom = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateFromCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());
        this.deliveryScheduleDateTo = dateTimeHelper.convertJST().substr(0, 10);
        this.deliveryScheduleDateToCal = dateTimeHelper.dateCalc(dateTimeHelper.convertJST());

        // 遷移フラグ
        this.transitionFlg = false;
      }

      // 初期化
      this.init();
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
      this.alertMessage = "";
      this.pickupAlertMessage = "";
      this.deliveryAlertMessage = "";
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    // 現時点で取引先との紐づきがないためコメントアウト
    // suppliersSelected(newValue, oldValue) {
    //   // 輸送担当営業所/ 集荷元/ 配達先クリア
    //   if (oldValue != null && oldValue != "") {
    //     this.officeList = [];
    //     this.officeSelected = "";
    //     this.pickupPointList = [];
    //     this.pickupPointSelected = "";
    //     this.deliveryPointList = [];
    //     this.deliveryPointSelected = "";
    //   }
    // },

    orderDateFromCal: {
      handler(val) {
        this.orderDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    orderDateToCal: {
      handler(val) {
        this.orderDateTo = this.formatDate(val);
      },
      deep: true,
    },
    pickupScheduleDateFromCal: {
      handler(val) {
        this.pickupScheduleDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    pickupScheduleDateToCal: {
      handler(val) {
        this.pickupScheduleDateTo = this.formatDate(val);
      },
      deep: true,
    },
    deliveryScheduleDateFromCal: {
      handler(val) {
        this.deliveryScheduleDateFrom = this.formatDate(val);
      },
      deep: true,
    },
    deliveryScheduleDateToCal: {
      handler(val) {
        this.deliveryScheduleDateTo = this.formatDate(val);
      },
      deep: true,
    },

    page: function (newValue) {
      this.page = newValue;
      this.getApiList(false, false);
    },
    sortOptions: {
      handler(items) {
        // console.log("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_TOD_002_SORT[sortBy].replaceAll("{0}", ascdesc); // + ascdesc;
          this.getApiList(false, false);
        }
      },
      deep: true,
    },
    /**
     * 輸送担当営業所取得
     */
    getOfficeSelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintOffice) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.officeList = [];
        //   this.officeSelected = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 選択した会社コードを取得
          // let supplierCd = this.supplierList.find(
          //   (element) => element.value === this.suppliersSelected
          // ).value;
          const params = {};
          // 取引先コード
          // params.customerCode = supplierCd;
          // 拠点管理種別（2=営業所を取得）
          params.baseOwnerType = "2";
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";
          // 拠点コード
          params.baseCode = val;
          // ------- ↓↓共通処理が完成次第、修正↓↓-----------
          const officeListTmp = getParameter.getTmsBizBaseMst(params);
          // const officeListTmp = [
          //   {
          //     text: "001（日高営業所）",
          //     value: "2400000001",
          //     name: "日高営業所",
          //   },
          // ];

          Promise.all([officeListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.officeList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.officeList.length = 0;
          this.officeList = [];
        }
      }
    },
    /**
     * 集荷元取得
     */
    getPickupSelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintPickup) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.pickupPointList = [];
        //   this.pickupPointSelected = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // ------- ↓↓共通処理が完成次第、修正↓↓-----------
          // 選択した会社コードを取得
          // let supplierCd = this.supplierList.find(
          //   (element) => element.value === this.suppliersSelected
          // ).value;
          const params = {};
          // 取引先コード
          // params.customerCode = supplierCd;
          // 拠点管理種別（1=拠点を取得）
          // params.baseOwnerType = "1";
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";
          // 拠点コード
          params.baseCode = val;
          const pickupListTmp = getParameter.getTmsBizBaseMst(params);
          // const pickupListTmp = [
          //   {
          //     text: "002（VCJ埼玉SC）",
          //     value: "2400000002",
          //     code: "4400710",
          //     name: "VCJ埼玉SC",
          //   },
          //   {
          //     text: "003（VC東名横浜）",
          //     value: "2400000003",
          //     code: "4400710",
          //     name: "VC東名横浜",
          //   },
          //   {
          //     text: "004（VC港北NT）",
          //     value: "2400000004",
          //     code: "4400710",
          //     name: "VC港北NT",
          //   },
          //   {
          //     text: "005（VC目黒）",
          //     value: "2400000005",
          //     code: "4400710",
          //     name: "VC目黒",
          //   },
          //   {
          //     text: "006（VC練馬）",
          //     value: "2400000006",
          //     code: "4400710",
          //     name: "VC練馬",
          //   },
          //   {
          //     text: "007（VC杉並）",
          //     value: "2400000007",
          //     code: "4400710",
          //     name: "VC杉並",
          //   },
          //   {
          //     text: "008（VC世田谷）",
          //     value: "2400000008",
          //     code: "4400710",
          //     name: "VC世田谷",
          //   },
          // ];

          Promise.all([pickupListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.pickupPointList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.pickupPointList.length = 0;
          this.pickupPointList = [];
        }
      }
    },
    /**
     * 配達先取得
     */
    getDeliverySelected(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.productHintDelivery) {
          return;
        }
        // 現時点で取引先との紐づきがないためコメントアウト
        // if (this.suppliersSelected == null || this.suppliersSelected == "") {
        //   this.deliveryPointList = [];
        //   this.deliveryPointSelected = "";
        //   return;
        // }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // ------- ↓↓共通処理が完成次第、修正↓↓-----------
          // 選択した会社コードを取得
          // let supplierCd = this.supplierList.find(
          //   (element) => element.value === this.suppliersSelected
          // ).value;
          const params = {};
          // 取引先コード
          // params.customerCode = supplierCd;
          // 拠点管理種別（1=拠点を取得）
          // params.baseOwnerType = "1";
          // 有効期限区分（1=すべて取得）
          params.validDateKbn = "1";
          // 自拠点区分（1=自拠点を含む）
          params.selfEstablishedLocationsKbn = "1";
          // 拠点コード
          params.baseCode = val;

          const deliveryListTmp = getParameter.getTmsBizBaseMst(params);
          // const deliveryListTmp = [
          //   {
          //     text: "002（VCJ埼玉SC）",
          //     value: "2400000002",
          //     code: "4400710",
          //     name: "VCJ埼玉SC",
          //   },
          //   {
          //     text: "003（VC東名横浜）",
          //     value: "2400000003",
          //     code: "4400710",
          //     name: "VC東名横浜",
          //   },
          //   {
          //     text: "004（VC港北NT）",
          //     value: "2400000004",
          //     code: "4400710",
          //     name: "VC港北NT",
          //   },
          //   {
          //     text: "005（VC目黒）",
          //     value: "2400000005",
          //     code: "4400710",
          //     name: "VC目黒",
          //   },
          //   {
          //     text: "006（VC練馬）",
          //     value: "2400000006",
          //     code: "4400710",
          //     name: "VC練馬",
          //   },
          //   {
          //     text: "007（VC杉並）",
          //     value: "2400000007",
          //     code: "4400710",
          //     name: "VC杉並",
          //   },
          //   {
          //     text: "008（VC世田谷）",
          //     value: "2400000008",
          //     code: "4400710",
          //     name: "VC世田谷",
          //   },
          // ];

          Promise.all([deliveryListTmp])
            .then((result) => {
              // 画面の初期値を設定します。
              this.deliveryPointList = result[0];
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        } else {
          this.deliveryPointList.length = 0;
          this.deliveryPointList = [];
        }
      }
    },
    supplierInput(val) {
      if (val ?? false) {
        // 表示内容とヒントが一致している場合は、確定した内容なので変更しない
        if (val == this.supplierHint) {
          return;
        }
        // スペース入力のみの場合、API側で全件検索となり画面が重くなるためAPIの実行をキャンセル
        if (val.trim().length === 0) {
          return;
        }
        if (val.length >= appConfig.CNT_CHAR_RELATED_PARTY) {
          // 取引先取得
          this.getCustomInfo(val);
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
}
.toatlNum {
  margin-left: 10px;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed1 {
  position: sticky !important;
  left: 0 !important;
  z-index: 6 !important;
}
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed2 {
  position: sticky !important;
  left: 50px !important;
  z-index: 6 !important;
}
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed3 {
  position: sticky !important;
  left: 170px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper .product-fixed3-search {
  position: sticky !important;
  left: 170px !important;
  z-index: 6 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(1) {
  position: sticky !important;
  left: 0 !important;
  z-index: 5 !important;
}
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper td:nth-child(2) {
  position: sticky !important;
  left: 50px !important;
  z-index: 5 !important;
}

#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr td {
  background-color: #ffffff;
}

//tdに背景色を設定(横固定をするため)
#listData.v-data-table--fixed-header ::v-deep .v-data-table__wrapper tbody tr:nth-child(odd) td {
  background-color: #dde5f0;
}

.add-textbox-remark {
  width: 40rem;
}
</style>
