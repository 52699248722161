<template>
  <div>
    <div ref="map" id="mapArea" style="height: 570px; width: 100%"></div>
    <SimpleDialog
      :isShow.sync="infoDialog.isOpen"
      :title="infoDialog.title"
      :message="infoDialog.message"
      :firstPageFlag="infoDialog.firstPageFlag"
      :thirdPageFlag="infoDialog.thirdPageFlag"
    />
  </div>
</template>
<script>
import SimpleDialog from "@/components/SimpleDialog";
import { appConfig } from "../../assets/scripts/js/AppConfig";

export default {
  components: {
    SimpleDialog,
  },
  data: () => ({
    initLatitude: 35.668079,
    initLongitude: 139.779233,
    map: null,
    row: null,
    routePath: null,
    loaded: undefined,
    // 確認メッセージダイアログ
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
      firstPageFlag: false,
      thirdPageFlag: false,
    },
  }),

  props: {
    // 車両アイコン表示に必要な項目
    carIconInfoList: {
      type: Array,
      required: true,
    },

    // 運行ルート線表示に必要な項目
    operationRouteLine: {
      type: Object,
      required: true,
    },
  },

  methods: {
    /**
     * 運行日を直接入力したり「＜」「＞」ボタンやカレンダーの日付を押下した場合、
     * 表示されている運行ルートや車両アイコンを消去し、その日付の運行状況を再表示する。
     */
    async redisplayCarIcons(currentLocationList) {
      let elements = document.getElementsByClassName("truckMarker");
      while (elements.length) {
        elements.item(0).remove();
      }
      // TODO テストのため座標を変更
      // currentLocationList[1].latitude = 32;
      // currentLocationList[1].longitude = 142;
      // currentLocationList[2].latitude = 23;
      // currentLocationList[2].longitude = 149;

      let me = this;
      var bounds = new window.google.maps.LatLngBounds();
      function CustomOverlay(map, row) {
        this.row = row;
        this.setMap(map); // オーバーレイをマップに追加
      }
      CustomOverlay.prototype = new window.google.maps.OverlayView();
      CustomOverlay.prototype.onAdd = function () {
        this.div = document.createElement("div");
        this.div.style.position = "absolute";
        this.div.classList.add("truckMarker", "truckMarker_" + this.row.status);
        var currentLatitude = this.row.latitude;
        var currentLongitude = this.row.longitude;
        var current = this.getProjection().fromLatLngToDivPixel(
          new window.google.maps.LatLng(currentLatitude, currentLongitude)
        );
        if (this.row.status > 0) {
          var prevLatitude = this.row.prevLatitude;
          var prevLongitude = this.row.prevLongitude;
          var prev = this.getProjection().fromLatLngToDivPixel(
            new window.google.maps.LatLng(prevLatitude, prevLongitude)
          );

          // 車両アイコンの傾けるための角度を算出する。（1つ前の座標の反対側を向く）
          var rad = Math.atan2(prev.y - current.y, prev.x - current.x);
          var deg = (rad * 180) / Math.PI;
          var sx = 1;
          if (deg > 90 || deg < -90) {
            sx = -1;
            var d = deg > 90 ? 180 : -180;
            deg = d - deg;
          }

          this.div.style.transform = "scaleX(" + sx + ") " + "rotate(" + deg + "deg)";
          this.div.style.transformOrigin = "0 100%";
        }
        this.div.addEventListener("click", () => {
          me.clickCarIcon(this.row.carSid);
        });
        this.getPanes().floatPane.appendChild(this.div);
        this.getPanes().overlayMouseTarget.appendChild(this.div);
      };
      CustomOverlay.prototype.draw = function () {
        const projection = this.getProjection();
        const position = projection.fromLatLngToDivPixel(this.get("position"));
        this.div.style.left = position.x + "px";
        this.div.style.top = position.y - 24 + "px";
      };
      CustomOverlay.prototype.onRemove = function () {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
        }
      };
      for (var row of currentLocationList) {
        if (row.latitude === "" && row.longitude === "") {
          var geocoder = new window.google.maps.Geocoder();
          await geocoder
            .geocode({ address: row.address }, (results, status) => {
              if (status == window.google.maps.GeocoderStatus.OK) {
                const overlay = new CustomOverlay(this.map, row);
                overlay.set(
                  "position",
                  new window.google.maps.LatLng(results[0].geometry.location)
                );
                bounds.extend(results[0].geometry.location);
              } else {
                this.infoDialog.message = "「" + row.address + "」がみつかりません";
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                this.infoDialog.firstPageFlag = true;
              }
            })
            .catch(() => {});
        } else {
          const overlay = new CustomOverlay(this.map, row);
          overlay.set("position", new window.google.maps.LatLng(row.latitude, row.longitude));
          bounds.extend(new window.google.maps.LatLng(row.latitude, row.longitude));
        }
      }
      this.map.fitBounds(bounds);
    },

    /**
     * 運行ルート線を消去する。
     *
     */
    async removeLine(paramRoutepath) {
      this.routePath = paramRoutepath;
      if (this.routePath) {
        this.routePath.setMap(null);
      }
    },
    /**
     * 運行ルート線を描画する。
     *
     */
    async drawLine(operationRoute) {
      var localOperationRoute = [];
      var bounds = new window.google.maps.LatLngBounds();
      for (var route of operationRoute.route) {
        if (route.latitude === "" && route.longitude === "") {
          var geocoder = new window.google.maps.Geocoder();
          await geocoder.geocode({ address: route.address }, (results, status) => {
            if (status == window.google.maps.GeocoderStatus.OK) {
              localOperationRoute.push(results[0].geometry.location);
              bounds.extend(results[0].geometry.location);
            } else {
              this.infoDialog.message = "「" + route.address + "」がみつかりません";
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
          });
        } else {
          localOperationRoute.push(new window.google.maps.LatLng(route.latitude, route.longitude));
          bounds.extend(new window.google.maps.LatLng(route.latitude, route.longitude));
        }
      }
      this.routepath = new window.google.maps.Polyline({
        path: localOperationRoute,
        strokeColor: "blue",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      this.$emit("childRoutepath", this.routepath);

      this.routepath.setMap(this.map);
      this.map.fitBounds(bounds);
    },
    removeTruckMarker() {
      let elements = document.getElementsByClassName("truckMarker");
      while (elements.length) {
        elements.item(0).remove();
      }
    },
    /**
     * 社号ボタンや車両アイコンを押下した場合、その車両アイコン（１つ）を表示する。
     */
    putCarIcon(operationRoute) {
      let elements = document.getElementsByClassName("truckMarker");
      while (elements.length) {
        elements.item(0).remove();
      }
      function CustomOverlay(map) {
        this.setMap(map); // オーバーレイをマップに追加
      }
      CustomOverlay.prototype = new window.google.maps.OverlayView();

      // 運行していない場合
      if (
        operationRoute.route[operationRoute.route.length - 1].latitude === "" &&
        operationRoute.route[operationRoute.route.length - 1].longitude === ""
      ) {
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
          { address: operationRoute.route[operationRoute.route.length - 1].address },
          (results, status) => {
            if (status == window.google.maps.GeocoderStatus.OK) {
              CustomOverlay.prototype.onAdd = function () {
                this.div = document.createElement("div");
                this.div.style.position = "absolute";
                this.div.classList.add("truckMarker", "truckMarker_" + operationRoute.status);
                this.getPanes().floatPane.appendChild(this.div); // オーバーレイをマップのDOMに追加
              };

              CustomOverlay.prototype.draw = function () {
                const projection = this.getProjection();
                const position = projection.fromLatLngToDivPixel(this.get("position"));
                this.div.style.left = position.x + "px";
                this.div.style.top = position.y - 24 + "px";
              };
              CustomOverlay.prototype.onRemove = function () {
                if (this.div) {
                  this.div.parentNode.removeChild(this.div);
                }
              };
              const overlay = new CustomOverlay(this.map);
              overlay.set("position", new window.google.maps.LatLng(results[0].geometry.location));

              this.map.setZoom(16);
            } else {
              this.infoDialog.message =
                "「" +
                operationRoute.route[operationRoute.route.length - 1].address +
                "」がみつかりません";
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              this.infoDialog.firstPageFlag = true;
            }
          }
        );
      } else {
        // 運行している場合
        CustomOverlay.prototype.onAdd = function () {
          this.div = document.createElement("div");
          this.div.style.position = "absolute";
          var currentLatitude = operationRoute.route[operationRoute.route.length - 1].latitude;
          var currentLongitude = operationRoute.route[operationRoute.route.length - 1].longitude;
          var current = this.getProjection().fromLatLngToDivPixel(
            new window.google.maps.LatLng(currentLatitude, currentLongitude)
          );

          // ステータスが終了以外の場合、車両アイコンを傾ける。
          if (operationRoute.status > 0) {
            var prevLatitude = operationRoute.route[operationRoute.route.length - 1].prevLatitude;
            var prevLongitude = operationRoute.route[operationRoute.route.length - 1].prevLongitude;
            var prev = this.getProjection().fromLatLngToDivPixel(
              new window.google.maps.LatLng(prevLatitude, prevLongitude)
            );

            // 車両アイコンの傾けるための角度を算出する。（1つ前の座標の反対側を向く）
            var rad = Math.atan2(prev.y - current.y, prev.x - current.x);
            var deg = (rad * 180) / Math.PI;
            var sx = 1;
            if (deg > 90 || deg < -90) {
              sx = -1;
              var d = deg > 90 ? 180 : -180;
              deg = d - deg;
            }

            this.div.style.transform = "scaleX(" + sx + ") " + "rotate(" + deg + "deg)";
            this.div.style.transformOrigin = "0 100%";
          }
          this.div.classList.add("truckMarker", "truckMarker_" + operationRoute.status);

          this.getPanes().floatPane.appendChild(this.div); // オーバーレイをマップのDOMに追加
        };

        CustomOverlay.prototype.draw = function () {
          const projection = this.getProjection();
          const position = projection.fromLatLngToDivPixel(this.get("position"));
          this.div.style.left = position.x + "px";
          this.div.style.top = position.y - 24 + "px";
        };

        CustomOverlay.prototype.onRemove = function () {
          if (this.div) {
            this.div.parentNode.removeChild(this.div);
            delete this.div;
          }
        };
        const overlay = new CustomOverlay(this.map);
        overlay.set(
          "position",
          new window.google.maps.LatLng(
            operationRoute.route[operationRoute.route.length - 1].latitude,
            operationRoute.route[operationRoute.route.length - 1].longitude
          )
        );
      }
    },
    clickCarIcon(carSid) {
      this.$emit("clickCarIcon", carSid);
    },
    init() {
      if (window.gmapLoaded == undefined) {
        let script = document.createElement("script");
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyCYXSNM04teYpRz2AyP6tMtrVvb6lqq1jQ&loading=async&callback=initMap";
        script.async = true;
        document.head.appendChild(script);
        window.gmapLoaded = new Promise((accept) => {
          // スクリプト呼び出し後のコールバック
          window["initMap"] = () => {
            accept("loaded");
            delete window["initMap"];
          };
        });
      }
      this.loaded = window.gmapLoaded;
      this.loaded.then(() => {
        const initLatLng = { lat: Number(this.initLatitude), lng: Number(this.initLongitude) };
        this.map = new window.google.maps.Map(this.$refs.map, {
          center: initLatLng,
          zoom: 13,
          styles: [
            {
              featureType: "poi", // 施設などの表示
              stylers: [{ visibility: "off" }],
            },
          ],
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        });
      });
    },
  },
};
</script>
